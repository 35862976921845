import { Col, Row, Typography, Flex as AntFlex, List } from "antd";
import styled, { css } from "styled-components";

export const Flex = styled.div`
  display: flex;
  align-items: ${({ align_items }) => align_items};
  justify-content: ${({ justify_content }) => justify_content};
  gap: ${({ gap }) => gap};
`;

export const ActionHeader = styled(Flex)`
  position: sticky;
  /* top: ${({ headerHeight }) => `calc(${headerHeight}px + 5px)`}; */
  top: ${({ headerHeight }) => `${headerHeight}px`};
  padding-bottom: 5px;
  background-color: var(--background-color);
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  z-index: 999;
  gap: 15px;
  margin-bottom: 15px;

  .left,
  .right {
    gap: 15px;
  }

  .left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* flex: 1; */
  }

  .right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: fit-content;
    /* white-space: nowrap; */
    /* gap: 10px; */
    /* flex: 1; */
    /* text-align: right; */
    /* flex-basis: 35%; */
    /* flex: 1 1 auto; */

    & > * {
      /* flex: 1; */
    }
  }
`;

export const Strong = styled.div`
  font-weight: bold;
`;

export const AccentBorder = styled.div`
  border: ${({ accentBorder = false }) => accentBorder && "2px solid var(--accent)"};
  border-radius: ${({ borderRadius = false }) => borderRadius && "var(--border-radius)"};
`;
export const InmateDetailsContainer = styled(AccentBorder)`
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const PageTabsWrapper = styled.div`
  background-color: white;
  flex: 1;
  color: var(--text-black);
`;
export const IntakeSectionHeader = styled.h6`
  position: relative;
  margin: 30px 0 15px 0;
  line-height: 20px;
  color: rgba(2, 87, 147, 0.9);
  font-weight: 600;
  text-transform: uppercase;

  & span {
    background-color: white;
    padding: 0 15px;
    margin-left: 20px;
    display: inline-block;
    position: relative;
    z-index: 1;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    /*right: 0;*/
    width: 100%;
    height: 2px;
    z-index: 0;
    background-color: rgba(82, 140, 181, 0.3);
  }
`;

export const PageContainer = styled.div`
  position: relative;
`;

export const StyledGridRow = styled(Row).withConfig({
  shouldForwardProp: (prop) => prop !== 'marginBottom' && prop !== 'marginTop'})
`
  ${({ marginTop }) => (marginTop ? `margin-top: ${marginTop};` : '')}
  ${({ marginBottom }) => (marginBottom ? `margin-bottom: ${marginBottom};` : '')}
.ant-col{
min-height:auto !important;
}
`
export const StyledGridCol = styled(Col).withConfig({
  shouldForwardProp: (prop) => prop !== 'marginBottom' && prop !== 'marginTop'})`

  ${({ marginTop }) => (marginTop ? `margin-top: ${marginTop};` : '')}
  ${({ marginBottom }) => (marginBottom ? `margin-bottom: ${marginBottom};` : '')}
  
`

export const StyledTypographyText = styled(Typography.Text).withConfig({
  shouldForwardProp: (prop) => prop !== 'textTransform' && prop !== 'color',
})`
  color: ${({ color }) => color || 'var(--text-color-white)'};
  text-transform: ${({ textTransform }) => textTransform || 'uppercase'};
  font-size: ${({ fontSize }) => fontSize || '12px'};
  font-weight: ${({ fontWeight }) => fontWeight || '600'};
`;

export const StyledBgDarkBlueMixin = css`
  background-color: var(--bg-dark-container);
  box-shadow: 0px 1px 1px rgba(17, 115, 153, 0.5) inset,
              0px -1px 0px rgba(5, 92, 126, 0.49) inset,
              2px 2px 7px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
`;

export const StyledPanelContainer = styled.div`
${StyledBgDarkBlueMixin}
`;

export const StyledFlex = styled(AntFlex).withConfig({
  shouldForwardProp: (prop) => prop !== 'marginBottom' && prop !== 'marginTop' && prop !== 'padding',
})`
  margin-top: ${({ marginTop }) => marginTop || 'inherit'};
  margin-bottom: ${({ marginBottom }) => marginBottom || 'inherit'};
  padding: ${({ padding }) => padding};
`
export const StyledList = styled(List)`

`
export const StyledUploadLinkPath = styled.div`
button,
  .btn-link-upload{
    position:relative;
    min-width:120px;
  }
  input[type="file"]{
   position:absolute;
   left:0;
   top:0;
   width:100%;
   height:100%;
   opacity:0;
  }
`
export const StyledDarkSectionTitle = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== 'bgColor' && prop !== 'textColor',
})`
  position: relative;
  margin: 10px 0 15px 0;
  line-height: 20px;
  color: ${({ textColor }) => textColor || 'var(--text-color-white)'};
  font-weight: 600;
  text-transform: uppercase;

  & span {
    background: ${({ bgColor }) => bgColor || 'rgb(4, 42, 67)'};
    padding: 0 10px;
    margin-left: 20px;
    display: inline-block;
    position: relative;
    z-index: 1;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    /*right: 0;*/
    width: 100%;
    height: 2px;
    z-index: 0;
    background-color: rgba(255, 255, 255, 0.15);
  }
`;

export const StyledStepContainer = styled.div`
position: relative;
display: flex;
min-height: 45px;
align-items: center;
justify-content: space-between;
background-color: var(--bg-light-container);
box-shadow: 0px 1px 0px rgba(50, 178, 230, 0.4) inset;
border-top-left-radius: 6px;
border-top-right-radius: 6px;
padding:0px;
button.btn-go-back{
 margin-left: 22px;
}
button.btn-go-next{
 margin-right: 22px;
}
 .panel-title{
   flex-grow: 1;
   font-size: 1.0765rem;
   text-transform: uppercase;
   font-weight: 400;
   text-shadow:0px 1px 3px rgba(6, 33, 59, 0.8);
   margin:0;
 }
 button.close-panel{
    font-size: 1.4rem;
    padding: 0 10px;
    min-height: 45px;
 }
 
.link-group{
  font-size: 1.0765rem;
  color:var(--text-color-blue);
  text-decoration:none;
    &.active{
    color:var(--text-color-white);
    }
}

`
export const StyledStepFormPanel = styled.div`
 --form-step-bg:#042A43;
 position: relative;
 background:var(--form-step-bg);
 border-radius:6px;
 overflow: auto;
`
export const StyledActionControlContainer = styled.div`
    --defaul-action-color:#0a334c;
    --accent-action-color:#7f4217;
    position: relative;
    display:flex;
    justify-content: space-between;
    min-height: 48px;
    .primary-control{
    position: relative;
    bottom: -5px;
    display:inline-flex;
    button{
       min-width: 150px;
    }
    
    
    &.primary-control.left{
        border-top-left-radius: 8px;
        border-top-right-radius: 5px;
        background:var(--defaul-action-color);
        &::before{
        content: "";
        position: absolute;
        width: 0px;
        height: 0px;
        right: -24px;
        top: 3px;
        border-left: 25px solid transparent;
        border-right: 25px solid transparent;
        border-bottom: 50px solid var(--defaul-action-color);
        }
    }
    &.primary-control.accent{
      background:var(--accent-action-color);
       &::before{
       border-bottom-color:var(--accent-action-color);
       }
    }

   }
`
export const StyledSectionHeading = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== 'textColor' && prop !== 'marginTop' && prop !== 'marginBottom',
})`
  position: relative;
  margin-top: ${({ marginTop }) => marginTop || '10px'};
  margin-bottom: ${({ marginBottom }) => marginBottom || '10px'};
  color: ${({ textColor }) => textColor || 'var(--text-black)'};
  line-height: 20px;
  font-weight: 600;
  text-transform: uppercase;

  & span {
    background-color: white;
    padding: 0 15px;
    margin-left: 20px;
    display: inline-block;
    position: relative;
    z-index: 1;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    /*right: 0;*/
    width: 100%;
    height: 2px;
    z-index: 0;
    background-color: rgba(0, 0, 0, 0.15);
  }
  &.unstyled-line{
   &::after{
   display:none;
   }
  }
`;
export const StyledSubTableContainer = styled.div`
    --background-color: #f1f1f1;
    padding: 10px 15px;
    background-color: var(--background-color);
    border-radius: var(--border-radius);
    h2{
    font-size: 1rem;
    text-transform: uppercase;
    color: rgba(2, 87, 147, 0.7);
    font-weight: 700;
    }
`
export const StyledInformationList = styled.div`
    --text-color: #041C2D;
    padding: 10px 15px;
    color: var(--text-color);
    .title{
    font-size: 1rem;
    text-transform: uppercase;
    color: rgba(2, 87, 147, 0.7);
    font-weight: 700;
    }
    .key{
    font-weight: 500;
    padding-right:15px;
    }
    .value{
     color: var(--text-black);
    }
    > div,.list-item {
    margin-bottom:9px;
    }
`