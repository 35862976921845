import React, { useMemo } from "react";
import { StyledAntCollapse } from "./styles";

const Collapse = ({
  items,
  accordion = false,
  defaultActiveKey = ["1"],
  size = "default",
  showExpandIcon = true,
  customPanelStyle = {},
  customClassName = "",
  bordered = false,
  customHeaderType, // stacked-start, stacked-end
  collapsible, // header | icon | disabled
  expandIconPosition="end",
  expandIcon, // use transform: isActive ? "rotate(180deg)" : "rotate(0deg)"
  customExpandIcon,
  onChange,
  ...props
}) => {
  const handleEndClick = (e) => {
    e.stopPropagation(); // Prevents triggering the collapse/expand behavior
  };

  // Header rendering abstraction
  const renderHeader = (header, type) => {
    switch (type) {
      case "stacked-start":
        return (
          <>
             <div className="start">
                <div className="stacked-icon">{header?.icon}</div>
                {header?.start && <div>{header?.start}</div>}
             </div>
             <div className="middle">
                <span className="header-title">{header?.title}</span>
                {header?.middle && <span>{header?.middle}</span>}
             </div>
          </>
        );
      case "stacked-end":
        return (
          <>
              <div className="start">
                {header?.start && <div>{header?.start}</div>}
             </div>
             <div className="middle">
                <span className="header-title">{header?.title}</span>
                {header?.middle && <span>{header?.middle}</span>}
             </div>
          </>
        );
      case "centered":
        return (
          <>
            <div className="header-title">{header.title}</div>
          </>
        );
      default:
        return <div className="default-header">{header.title || header}</div>;
    }
  };

  // Memoized panel data to avoid re-computation
  const memoizedItems = useMemo(
    () =>
      items.map((item) => ({
        key: item.key,
        label: renderHeader(item.label, customHeaderType),
        className:`header-${customHeaderType}`,
        extra:item?.label?.end ? item.label.end:null,
        children: item.children,
        style: {...item.style },
      })),
    [items, customHeaderType, customPanelStyle]
  );

  return (
    <StyledAntCollapse
      accordion={accordion}
      size={size}
      defaultActiveKey={defaultActiveKey}
      expandIconPosition={expandIconPosition}
      bordered={bordered}
      collapsible={collapsible}
      onChange={onChange}
      className={`${!showExpandIcon || customExpandIcon ? "no-expand-icon" : ""} ${customClassName}`}
      expandIcon={expandIcon}
      items={memoizedItems}
      {...props}
      style={customPanelStyle}
    />
  );
};

export default Collapse;
