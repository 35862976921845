import { useEffect, useRef, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import "./App.css";
import { ContentContainer, MainContainer } from "./AppStyles";
import { Flex } from "./common/styles";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import { useLocation } from "react-router-dom";
import API_ENDPOINTS from "@/util/api";
import { useClientContext } from "@/contexts/ClientparamsContext";
import { useRefContext } from "@/contexts/RefListContext";
import api from "./services/axios_custom";
import { useDispatch } from "react-redux";
import { setLoading, setRefLists } from "./slices/refListSlice";
import SetReflist from "./util/common_function";
import {
  setBookings,
  setBookingsNull,
  setLastBookingsFromAPI,
} from "./slices/bookingsSlice";

const App = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const sidebarHiddenRoutes = ["/dashboard"];
  const [isSidebarHiddenForRoute, setIsSidebarHiddenForRoute] = useState(false);
  const [headerHeight, setHeaderHeight] = useState();
  const { setClientParam } = useClientContext();
  const [isClientParamLoading, setClientParamLoading] = useState(false);
  const [reflist, setReflist] = useState(
    // {
    //   "searchType": "NAME-IN",
    //   "searchValue": ["Race", 'Sex']
    // }
    {
      searchType: "ALL",
      searchValue: [],
    }
  );

  const headerRef = useRef();
  const mainContainerRef = useRef();
  // const navigate = useNavigate();

  useEffect(() => {
    const sidebarVisible = sidebarHiddenRoutes.includes(
      location.pathname.toLowerCase()
    );
    setIsSidebarHiddenForRoute(sidebarVisible);
  }, [location?.pathname]);

  useEffect(() => {
    setHeaderHeight(headerRef?.current?.clientHeight);
  }, [headerRef]);

  let refListinfo = JSON.stringify(reflist);

  useEffect(() => {
    setClientParamLoading(true);
    getAllClientParams();
    getRefList();
    getLatestBookings();
  }, []);

  const getAllClientParams = () => {
    api
      .get(API_ENDPOINTS.clientParams)
      .then((response) => {
        setClientParam(response.data);
        // localStorage.setItem('clientParam', JSON.stringify(response.data));
      })
      .catch((error) => {
        console.error(
          "Request failed:",
          error.response ? error.response.data : error.message
        );
      });
  };

  const getRefList = () => {
    api
      .post(API_ENDPOINTS.refList, refListinfo)
      .then((response) => {
        dispatch(setRefLists(response?.data));
        dispatch(setLoading(false));
      })
      .catch((error) => {
        console.error(
          "Request failed:",
          error.response ? error.response.data : error.message
        );
      });
  };

  const getLatestBookings = () => {
    api
      .get(API_ENDPOINTS.latestBooking)
      .then((response) => {
        if (response.status === 200 && response.data) {
          // dispatch(setBookings(response?.data));
          dispatch(setLastBookingsFromAPI(response?.data));
        }
      })
      .catch((error) => {
        console.error(
          "Request failed:",
          error.response ? error.response.data : error.message
        );
      });
  };

  return (
    <>
      <SetReflist></SetReflist>
      <MainContainer ref={mainContainerRef}>
        <Header ref={headerRef} />
        <Flex align_items={"stretch"} gap={"20px"}>
          {!isSidebarHiddenForRoute && <Sidebar headerHeight={headerHeight} />}
          <ContentContainer headerHeight={headerHeight}>
            <Outlet context={{ headerHeight }} />
          </ContentContainer>
        </Flex>
      </MainContainer>
    </>
  );
};

export default App;
