import { DatePicker } from "antd";
import styled from "styled-components";

export const StyledDatePicker = styled(DatePicker)`
  width: 100%;
`;

export const StyledRangePicker = styled(DatePicker.RangePicker)`
  width: 100%;
`;
