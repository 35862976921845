import { configureStore } from "@reduxjs/toolkit";
import bookingsReducer from "./slices/bookingsSlice";
import bookingIntakeReducer from "./slices/bookingIntakeSlice";
import medicalInfoReducer from "./slices/medicalInfoSlice";
import docsReducer from "./slices/docsSlice";
import bookingPhotoReducer from "./slices/bookingPhotoSlice";
import refListReducer from "./slices/refListSlice";

export default configureStore({
  reducer: {
    // Define a top-level state field named `bookings`, handled by `bookingsSlice`
    bookings: bookingsReducer,
    bookingIntake: bookingIntakeReducer,
    medicalData: medicalInfoReducer,
    docsData: docsReducer,
    imgData: bookingPhotoReducer,
    refListReducer: refListReducer,
  },
});
