import { IntakeSectionHeader, StyledGridRow } from "@/common/styles";
import { staticFields } from "@/util/common_variable";
import { Formik } from "formik";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { FormContainer } from "./styles";
import DynamicForm from "@/common/DynamicForm";
import { Form } from "antd";
import dayjs from "dayjs";
import { findAgefromDOB, isNonEmptyVariable } from "@/util/common_function";
import { useDispatch, useSelector } from "react-redux";
import { setBookings } from "@/slices/bookingsSlice";

export const PERSON = {
  INMATE: "INMATE",
  VISITOR: "VISITOR",
  BOOKINGS: "BOOKINGS",
};

const Personal = forwardRef(
  ({ header, person = PERSON.INMATE, personalFormRef }) => {
    const dispatch = useDispatch();
    const { bookings, bookingsFieldsEnabled } = useSelector(
      (state) => state?.bookings
    );
    const [form] = Form.useForm();
    const [personalInfoForm, setPersonalInfoForm] = useState(null);

    useEffect(() => {
      const bio = bookings?.bio?.[0];
      if (isNonEmptyVariable(bio)) {
        setPersonalInfoForm(() => ({
          ...bio,
          dob: isNonEmptyVariable(bio?.dob) ? dayjs(bio?.dob) : null,
          dlExpiration: isNonEmptyVariable(bio?.dlExpiration)
            ? dayjs(bio?.dlExpiration)
            : null,
          ageAtEntry: isNonEmptyVariable(bio?.dob)
            ? findAgefromDOB(dayjs(bio?.dob)?.format("MM/DD/YYYY"))
            : null,
        }));
      } else {
        setPersonalInfoForm(null);
      }
    }, [bookings]);

    useEffect(() => {
      if (personalInfoForm === null) {
        form.resetFields();
      } else {
        form.setFieldsValue(personalInfoForm);
      }
    }, [personalInfoForm]);

    // Expose form methods to parent
    useImperativeHandle(personalFormRef, () => ({
      submitForm: () => form.submit(),
      resetForm: () => form.resetFields(),
      getFormValues: () => form.getFieldsValue(),
      getFieldsError: () => form.getFieldsError(),
      isFieldsValidating: () => form.isFieldsValidating(),
      validateFields: () => form.validateFields(),
      form: form,
    }));

    const updateForm = (data) => {
      dispatch(
        setBookings({
          bio: [
            {
              ...bookings?.bio?.[0],
              ...data,
            },
          ],
          arrestReports: [
            {
              ...bookings?.arrestReports?.[0],
              inmateTypeId: data?.ageAtEntry < 18 ? 154540005 : 154540001,
            },
          ],
        })
      );
    };

    return (
      <FormContainer>
        {header && (
          <IntakeSectionHeader>
            <span>{header}</span>
          </IntakeSectionHeader>
        )}

        <Form
          form={form}
          disabled={!bookingsFieldsEnabled}
          initialValues={personalInfoForm}
          onBlur={(e) => {
            if (e?.target?.id == "dob" || e?.target?.id === "dlExpiration") {
              return;
            }

            const values = form.getFieldsValue();
            values.dob = values?.dob
              ? dayjs(values?.dob)?.format("MM/DD/YYYY")
              : null;
            values.dlExpiration = values?.dlExpiration
              ? dayjs(values?.dlExpiration)?.format("MM/DD/YYYY")
              : null;
            updateForm(values);
          }}
          onFieldsChange={(sf) => {
            if (
              sf?.[0]?.name?.[0] === "dob" ||
              sf?.[0]?.name?.[0] === "dlExpiration"
            ) {
              const values = form.getFieldsValue();

              const data = {
                dob: values?.dob
                  ? dayjs(values?.dob)?.format("MM/DD/YYYY")
                  : null,
                dlExpiration: values?.dlExpiration
                  ? dayjs(values?.dlExpiration)?.format("MM/DD/YYYY")
                  : null,
              };

              const age = findAgefromDOB(data?.dob);

              data.ageAtEntry = age;
              updateForm(data);
            }
          }}
        >
          <StyledGridRow gutter={[16]}>
            {staticFields?.personalInfo[person]?.map((sf, index) => {
              return (
                <DynamicForm
                  formAttributes={sf}
                  i={index}
                  form={form}
                  bookingsFieldsEnabled={bookingsFieldsEnabled}
                />
              );
            })}
          </StyledGridRow>
        </Form>
      </FormContainer>
    );
  }
);

export default Personal;
