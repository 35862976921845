import { createSlice } from "@reduxjs/toolkit";

const medicalInfoSlice = createSlice({
  name: "medicalInfo",
  initialState: {
    medicalData: [],
  },
  reducers: {
    setMedicalData: (state, action) => {
      state.medicalData = action.payload;
    },
    getMedicalData: (state) => {
      return state.medicalData;
    },
  },
});

export const { setMedicalData, getMedicalData } = medicalInfoSlice.actions;
export default medicalInfoSlice.reducer;
