import { INPUT_VARIANT } from "../const";
import { FormControl } from "../styles";

const TextField = ({ id, name, label, inputType = "", variant, required, ...rest }) => {
  return (
    <>
      {inputType === "" && rest.as !== "textarea" && (
        <FormControl id={id} name={name} className={`${rest?.className}`} variant={variant} {...rest} />
      )}
      {inputType === "password" && (
        <FormControl.Password id={id} name={name} className={`${rest?.className}`} variant={variant}  {...rest} />
      )}
      {(inputType === "textarea" || rest.as === "textarea") && (
        <FormControl.TextArea id={id} name={name} className={`${rest?.className}`} variant={variant}  {...rest} />
      )}
    </>
  );
};

export default TextField;
