export const staffList = [
    {
      "staffID": 1,
      "userName": "System"
    },
    {
      "staffID": 2,
      "userName": "MJROFF"
    },
    {
      "staffID": 3,
      "userName": "KIRBYKE"
    },
    {
      "staffID": 5,
      "userName": "DFISCHER"
    },
    {
      "staffID": 7,
      "userName": "FRANK"
    },
    {
      "staffID": 8,
      "userName": "RHOCKING"
    },
    {
      "staffID": 10,
      "userName": "KRMCDERMOTT"
    },
    {
      "staffID": 12,
      "userName": "PDDELANGE"
    },
    {
      "staffID": 16,
      "userName": "DTPALKKI"
    },
    {
      "staffID": 18,
      "userName": "harsh"
    },
    {
      "staffID": 25,
      "userName": "JBACKOWSKI"
    },
    {
      "staffID": 26,
      "userName": "JSMULHERN"
    },
    {
      "staffID": 27,
      "userName": "chethan"
    },
    {
      "staffID": 30,
      "userName": "SROMAN"
    },
    {
      "staffID": 38,
      "userName": "SRICHARDS"
    },
    {
      "staffID": 39,
      "userName": "CMPLANT"
    },
    {
      "staffID": 47,
      "userName": "HFREDLUND"
    },
    {
      "staffID": 52,
      "userName": "DRGALBR"
    },
    {
      "staffID": 54,
      "userName": "DJPACHOLL"
    },
    {
      "staffID": 55,
      "userName": "DSCHULDT"
    },
    {
      "staffID": 56,
      "userName": "KLSTRONG"
    },
    {
      "staffID": 57,
      "userName": "NBERGSTROM"
    },
    {
      "staffID": 60,
      "userName": "CGTHOMPSON"
    },
    {
      "staffID": 62,
      "userName": "ESWARTOUT"
    },
    {
      "staffID": 63,
      "userName": "PAMCNAIR"
    },
    {
      "staffID": 64,
      "userName": "MSLAVIK"
    },
    {
      "staffID": 69,
      "userName": "JMYERS"
    },
    {
      "staffID": 70,
      "userName": "SYOUNG"
    },
    {
      "staffID": 71,
      "userName": "TBANDEMER"
    },
    {
      "staffID": 72,
      "userName": "LSHERRETT"
    },
    {
      "staffID": 73,
      "userName": "RJOEL"
    },
    {
      "staffID": 75,
      "userName": "LOCHSNER"
    },
    {
      "staffID": 76,
      "userName": "NBUBAR"
    },
    {
      "staffID": 77,
      "userName": "BCREGO"
    },
    {
      "staffID": 79,
      "userName": "LDELOSREYES"
    },
    {
      "staffID": 80,
      "userName": "BHERMANSON"
    },
    {
      "staffID": 81,
      "userName": "JHLAVINKA"
    },
    {
      "staffID": 84,
      "userName": "DPETERSON"
    },
    {
      "staffID": 85,
      "userName": "JMARO"
    },
    {
      "staffID": 86,
      "userName": "BDEAN"
    },
    {
      "staffID": 87,
      "userName": "DLESLIE"
    },
    {
      "staffID": 91,
      "userName": "WRYTHER"
    },
    {
      "staffID": 92,
      "userName": "CHAUGLAND"
    },
    {
      "staffID": 94,
      "userName": "SCATER"
    },
    {
      "staffID": 95,
      "userName": "LDENNY"
    },
    {
      "staffID": 96,
      "userName": "CEMBRY"
    },
    {
      "staffID": 97,
      "userName": "KFOURNIER"
    },
    {
      "staffID": 99,
      "userName": "DFIELDS"
    },
    {
      "staffID": 100,
      "userName": "JKREUN"
    },
    {
      "staffID": 101,
      "userName": "JWENGLER"
    },
    {
      "staffID": 104,
      "userName": "RSPETS"
    },
    {
      "staffID": 105,
      "userName": "ATHOMPSON"
    },
    {
      "staffID": 107,
      "userName": "DLEMKE"
    },
    {
      "staffID": 108,
      "userName": "MREDMAN"
    },
    {
      "staffID": 109,
      "userName": "MHEHR"
    },
    {
      "staffID": 110,
      "userName": "JALLRAM"
    },
    {
      "staffID": 111,
      "userName": "DNOLAN"
    },
    {
      "staffID": 112,
      "userName": "SFENTON"
    },
    {
      "staffID": 113,
      "userName": "MBLESSING"
    },
    {
      "staffID": 114,
      "userName": "CWOOD"
    },
    {
      "staffID": 115,
      "userName": "CVETSCH"
    },
    {
      "staffID": 119,
      "userName": "TPACHOLL"
    },
    {
      "staffID": 121,
      "userName": "MJOHNSON"
    },
    {
      "staffID": 122,
      "userName": "SJOHNSON"
    },
    {
      "staffID": 123,
      "userName": "JVEVEA"
    },
    {
      "staffID": 125,
      "userName": "AYOUNG"
    },
    {
      "staffID": 126,
      "userName": "DTEDROW"
    },
    {
      "staffID": 128,
      "userName": "MNELSON"
    },
    {
      "staffID": 129,
      "userName": "TDUNCAN"
    },
    {
      "staffID": 131,
      "userName": "JPACKER"
    },
    {
      "staffID": 132,
      "userName": "TKEEHN"
    },
    {
      "staffID": 133,
      "userName": "DNORDLAND"
    },
    {
      "staffID": 134,
      "userName": "CBRYNTESON"
    },
    {
      "staffID": 135,
      "userName": "ESTAFFORD"
    },
    {
      "staffID": 139,
      "userName": "BCOCHRAN"
    },
    {
      "staffID": 140,
      "userName": "TSMITH"
    },
    {
      "staffID": 142,
      "userName": "JBOELTER"
    },
    {
      "staffID": 144,
      "userName": "BFOSS"
    },
    {
      "staffID": 145,
      "userName": "DJOEL"
    },
    {
      "staffID": 146,
      "userName": "CSOHN"
    },
    {
      "staffID": 147,
      "userName": "DLINNGREN"
    },
    {
      "staffID": 148,
      "userName": "MWOITEL"
    },
    {
      "staffID": 149,
      "userName": "JHILL"
    },
    {
      "staffID": 150,
      "userName": "WHILDEBRAND"
    },
    {
      "staffID": 151,
      "userName": "SHIRSCH"
    },
    {
      "staffID": 163,
      "userName": "DKISSIAH"
    },
    {
      "staffID": 171,
      "userName": "RECKMAN"
    },
    {
      "staffID": 172,
      "userName": "EHOWARD"
    },
    {
      "staffID": 175,
      "userName": "LWAGAMAN"
    },
    {
      "staffID": 177,
      "userName": "PTROST"
    },
    {
      "staffID": 179,
      "userName": "MGUNDERMAN"
    },
    {
      "staffID": 185,
      "userName": "RPIECEK"
    },
    {
      "staffID": 186,
      "userName": "JFISCHER"
    },
    {
      "staffID": 187,
      "userName": "SLARSON"
    },
    {
      "staffID": 188,
      "userName": "JSM"
    },
    {
      "staffID": 191,
      "userName": "GREG"
    },
    {
      "staffID": 16665,
      "userName": "APCHAMPA"
    },
    {
      "staffID": 17382,
      "userName": "KMCNALLAN"
    },
    {
      "staffID": 17383,
      "userName": "IMCORRECTIONS"
    },
    {
      "staffID": 17384,
      "userName": "EHERSCHBERGER"
    },
    {
      "staffID": 17385,
      "userName": "AANDERSON"
    },
    {
      "staffID": 17390,
      "userName": "PHATCH"
    },
    {
      "staffID": 17391,
      "userName": "DHAUGEN"
    },
    {
      "staffID": 17392,
      "userName": "PKING"
    },
    {
      "staffID": 17394,
      "userName": "RKTHOMA"
    },
    {
      "staffID": 17399,
      "userName": "DESKIERKA"
    },
    {
      "staffID": 17400,
      "userName": "MWYLIE"
    },
    {
      "staffID": 17401,
      "userName": "MEMERSON"
    },
    {
      "staffID": 17402,
      "userName": "BELMER"
    },
    {
      "staffID": 17403,
      "userName": "LDOHENY"
    },
    {
      "staffID": 17404,
      "userName": "GDUBAY"
    },
    {
      "staffID": 17405,
      "userName": "RKARNITZ"
    },
    {
      "staffID": 17407,
      "userName": "BOWENS"
    },
    {
      "staffID": 17409,
      "userName": "SBOSKOWITZ"
    },
    {
      "staffID": 17411,
      "userName": "AMENDOZA"
    },
    {
      "staffID": 17413,
      "userName": "WPLESSEL"
    },
    {
      "staffID": 17415,
      "userName": "JSABO"
    },
    {
      "staffID": 17416,
      "userName": "BCOLLIER"
    },
    {
      "staffID": 17417,
      "userName": "JHASSEL"
    },
    {
      "staffID": 17418,
      "userName": "MGEORGE"
    },
    {
      "staffID": 17419,
      "userName": "DWYMORE"
    },
    {
      "staffID": 17420,
      "userName": "BFRITZE"
    },
    {
      "staffID": 17421,
      "userName": "LTAYLOR"
    },
    {
      "staffID": 17424,
      "userName": "EDONARSKI"
    },
    {
      "staffID": 17425,
      "userName": "BPIERSON"
    },
    {
      "staffID": 17427,
      "userName": "ERICE"
    },
    {
      "staffID": 17428,
      "userName": "RMJONES"
    },
    {
      "staffID": 17429,
      "userName": "SSCHELL"
    },
    {
      "staffID": 17430,
      "userName": "KWOLLACK"
    },
    {
      "staffID": 17431,
      "userName": "DSCHULDT"
    },
    {
      "staffID": 17432,
      "userName": "DMAROTTE"
    },
    {
      "staffID": 17434,
      "userName": "MCARLSON"
    },
    {
      "staffID": 17435,
      "userName": "BATKINS"
    },
    {
      "staffID": 17436,
      "userName": "NPUELSTON"
    },
    {
      "staffID": 17437,
      "userName": "RCORNELIUS"
    },
    {
      "staffID": 17438,
      "userName": "CAPACE"
    },
    {
      "staffID": 17440,
      "userName": "LSERY"
    },
    {
      "staffID": 17444,
      "userName": "KSIEVERT"
    },
    {
      "staffID": 17446,
      "userName": "AHEDBERG"
    },
    {
      "staffID": 17448,
      "userName": "DPBOSAC"
    },
    {
      "staffID": 17449,
      "userName": "DFCASAL"
    },
    {
      "staffID": 17451,
      "userName": "DSDAHLB"
    },
    {
      "staffID": 17452,
      "userName": "DKENGEL"
    },
    {
      "staffID": 17454,
      "userName": "RAGOSSE"
    },
    {
      "staffID": 17455,
      "userName": "JKHEXUM"
    },
    {
      "staffID": 17456,
      "userName": "WJHUSS"
    },
    {
      "staffID": 17457,
      "userName": "SLKOHOU"
    },
    {
      "staffID": 17459,
      "userName": "KMMIDDL"
    },
    {
      "staffID": 17460,
      "userName": "DBMITCH"
    },
    {
      "staffID": 17462,
      "userName": "JJSCHLI"
    },
    {
      "staffID": 17463,
      "userName": "SASKJER"
    },
    {
      "staffID": 17464,
      "userName": "SSSTEIL"
    },
    {
      "staffID": 17465,
      "userName": "SRWILSO"
    },
    {
      "staffID": 17466,
      "userName": "EKYOUNG"
    },
    {
      "staffID": 17467,
      "userName": "JDAYKEN"
    },
    {
      "staffID": 17469,
      "userName": "JMBENDI"
    },
    {
      "staffID": 17470,
      "userName": "KMMARQU"
    },
    {
      "staffID": 17471,
      "userName": "KJNORIN"
    },
    {
      "staffID": 17472,
      "userName": "VTRICKE"
    },
    {
      "staffID": 17475,
      "userName": "SJTUSA"
    },
    {
      "staffID": 17476,
      "userName": "KLEDGER"
    },
    {
      "staffID": 17477,
      "userName": "EMJOHNS"
    },
    {
      "staffID": 17479,
      "userName": "PKKRINK"
    },
    {
      "staffID": 17480,
      "userName": "KJNEBEL"
    },
    {
      "staffID": 17481,
      "userName": "GLROEHL"
    },
    {
      "staffID": 17482,
      "userName": "PAROLEK"
    },
    {
      "staffID": 17483,
      "userName": "MASHARB"
    },
    {
      "staffID": 17484,
      "userName": "CAZAK"
    },
    {
      "staffID": 17486,
      "userName": "RFANDER"
    },
    {
      "staffID": 17487,
      "userName": "CMKOHAN"
    },
    {
      "staffID": 17488,
      "userName": "DEBREYE"
    },
    {
      "staffID": 17489,
      "userName": "JEDURIN"
    },
    {
      "staffID": 17490,
      "userName": "BJFULL"
    },
    {
      "staffID": 17491,
      "userName": "LMGOTTW"
    },
    {
      "staffID": 17492,
      "userName": "MDJAKUB"
    },
    {
      "staffID": 17494,
      "userName": "SFLARSO"
    },
    {
      "staffID": 17495,
      "userName": "SMLEROM"
    },
    {
      "staffID": 17496,
      "userName": "GMPRUYN"
    },
    {
      "staffID": 17497,
      "userName": "MGROMAN"
    },
    {
      "staffID": 17500,
      "userName": "BMUSILT"
    },
    {
      "staffID": 17501,
      "userName": "DLWARKE"
    },
    {
      "staffID": 17502,
      "userName": "DTGERJE"
    },
    {
      "staffID": 17503,
      "userName": "SLKRAAC"
    },
    {
      "staffID": 17504,
      "userName": "QJKURTZ"
    },
    {
      "staffID": 17505,
      "userName": "TSLEA"
    },
    {
      "staffID": 17507,
      "userName": "KAMARSH"
    },
    {
      "staffID": 17508,
      "userName": "ACPETER"
    },
    {
      "staffID": 17509,
      "userName": "ALLEHTO"
    },
    {
      "staffID": 17510,
      "userName": "MORLANDO"
    },
    {
      "staffID": 17512,
      "userName": "JROKEH"
    },
    {
      "staffID": 17514,
      "userName": "DANDERSON"
    },
    {
      "staffID": 17516,
      "userName": "ATKNOTZ"
    },
    {
      "staffID": 17517,
      "userName": "TDAY"
    },
    {
      "staffID": 17518,
      "userName": "KRHALWEG"
    },
    {
      "staffID": 17519,
      "userName": "RJKREYER"
    },
    {
      "staffID": 17520,
      "userName": "MWLONGBE"
    },
    {
      "staffID": 17521,
      "userName": "JPLATTNER"
    },
    {
      "staffID": 17522,
      "userName": "SSCHREMPP"
    },
    {
      "staffID": 17523,
      "userName": "DWILEY"
    },
    {
      "staffID": 17524,
      "userName": "POHARA"
    },
    {
      "staffID": 17525,
      "userName": "DJJENKIN"
    },
    {
      "staffID": 17526,
      "userName": "THWELLS"
    },
    {
      "staffID": 17527,
      "userName": "JLSTUART"
    },
    {
      "staffID": 17529,
      "userName": "PSOMMER"
    },
    {
      "staffID": 17530,
      "userName": "DDOUGLAS"
    },
    {
      "staffID": 17531,
      "userName": "EEGLY"
    },
    {
      "staffID": 17532,
      "userName": "BFUERST"
    },
    {
      "staffID": 17536,
      "userName": "KKLOSTERMAN"
    },
    {
      "staffID": 17537,
      "userName": "MLAPHAM"
    },
    {
      "staffID": 17539,
      "userName": "GRPATTER"
    },
    {
      "staffID": 17540,
      "userName": "BPODANY"
    },
    {
      "staffID": 17542,
      "userName": "PNELSON"
    },
    {
      "staffID": 17543,
      "userName": "TSTRUSINSKI"
    },
    {
      "staffID": 17546,
      "userName": "TMULEK"
    },
    {
      "staffID": 17547,
      "userName": "SCOULTER"
    },
    {
      "staffID": 17548,
      "userName": "JBECKETT"
    },
    {
      "staffID": 17550,
      "userName": "DEDWARDS"
    },
    {
      "staffID": 17552,
      "userName": "BLARSON"
    },
    {
      "staffID": 17553,
      "userName": "SKNEALING"
    },
    {
      "staffID": 17554,
      "userName": "KIVORY"
    },
    {
      "staffID": 17556,
      "userName": "CHOEKE"
    },
    {
      "staffID": 17557,
      "userName": "AJLINDBE"
    },
    {
      "staffID": 17558,
      "userName": "MJORGENSEN"
    },
    {
      "staffID": 17559,
      "userName": "LOLEARY"
    },
    {
      "staffID": 17560,
      "userName": "TBINDER"
    },
    {
      "staffID": 17562,
      "userName": "MANDERSOHN"
    },
    {
      "staffID": 17563,
      "userName": "ARYCHNER"
    },
    {
      "staffID": 17564,
      "userName": "MSCHANTZEN"
    },
    {
      "staffID": 17565,
      "userName": "RYOUNG"
    },
    {
      "staffID": 17568,
      "userName": "JLPSYCK"
    },
    {
      "staffID": 17570,
      "userName": "KKKIRBY"
    },
    {
      "staffID": 17573,
      "userName": "JALDERINK"
    },
    {
      "staffID": 17574,
      "userName": "WHUNT"
    },
    {
      "staffID": 17576,
      "userName": "AXWAKAL"
    },
    {
      "staffID": 17581,
      "userName": "ASCHULDT"
    },
    {
      "staffID": 17582,
      "userName": "JAOLSON"
    },
    {
      "staffID": 17583,
      "userName": "JMARTIN"
    },
    {
      "staffID": 17584,
      "userName": "ADORNER"
    },
    {
      "staffID": 17616,
      "userName": "RWIGGIN"
    },
    {
      "staffID": 17617,
      "userName": "DKETTNER"
    },
    {
      "staffID": 17618,
      "userName": "ASCHROEDER"
    },
    {
      "staffID": 17619,
      "userName": "JMZIMME"
    },
    {
      "staffID": 17620,
      "userName": "DPETERS"
    },
    {
      "staffID": 17621,
      "userName": "GSCHNEIDER"
    },
    {
      "staffID": 17622,
      "userName": "TGRUENIG"
    },
    {
      "staffID": 17625,
      "userName": "JSCHOEBERL"
    },
    {
      "staffID": 17628,
      "userName": "MWELLMAN"
    },
    {
      "staffID": 17629,
      "userName": "BHILL"
    },
    {
      "staffID": 17631,
      "userName": "KWELLMAN"
    },
    {
      "staffID": 17632,
      "userName": "LAHERSC"
    },
    {
      "staffID": 17638,
      "userName": "CEKUM"
    },
    {
      "staffID": 17640,
      "userName": "BCWITHE"
    },
    {
      "staffID": 17641,
      "userName": "JPILZ"
    },
    {
      "staffID": 17642,
      "userName": "MSTILLER"
    },
    {
      "staffID": 17647,
      "userName": "ELITESOFT"
    },
    {
      "staffID": 17648,
      "userName": "BKTWH"
    },
    {
      "staffID": 17649,
      "userName": "BKTMED"
    },
    {
      "staffID": 17650,
      "userName": "JMAUST"
    },
    {
      "staffID": 17654,
      "userName": "JMILLER"
    },
    {
      "staffID": 17655,
      "userName": "JESSIG"
    },
    {
      "staffID": 17656,
      "userName": "LCHRISTOFFERSON"
    },
    {
      "staffID": 17657,
      "userName": "RFRANKLIN"
    },
    {
      "staffID": 17658,
      "userName": "JHENRICHSEN"
    },
    {
      "staffID": 17659,
      "userName": "MHLAVINKA"
    },
    {
      "staffID": 17660,
      "userName": "MTURNBOM"
    },
    {
      "staffID": 17668,
      "userName": "MJBERGE"
    },
    {
      "staffID": 17672,
      "userName": "JRTHORS"
    },
    {
      "staffID": 17673,
      "userName": "SAHAHN"
    },
    {
      "staffID": 17674,
      "userName": "WLHALLE"
    },
    {
      "staffID": 17676,
      "userName": "BJLEVER"
    },
    {
      "staffID": 17677,
      "userName": "SWARTOUTM"
    },
    {
      "staffID": 17678,
      "userName": "SWARTOUTW"
    },
    {
      "staffID": 17681,
      "userName": "LLPAYNE"
    },
    {
      "staffID": 17682,
      "userName": "CNELSON"
    },
    {
      "staffID": 17684,
      "userName": "MKNELSO"
    },
    {
      "staffID": 17687,
      "userName": "FAMUNOZ"
    },
    {
      "staffID": 17688,
      "userName": "JHATTON"
    },
    {
      "staffID": 17689,
      "userName": "EBENJAMIN"
    },
    {
      "staffID": 17690,
      "userName": "JJSADLER"
    },
    {
      "staffID": 17692,
      "userName": "JCMOBRAT"
    },
    {
      "staffID": 17693,
      "userName": "RGCOLLER"
    },
    {
      "staffID": 17696,
      "userName": "KRMATTI"
    },
    {
      "staffID": 17697,
      "userName": "DTADAMS"
    },
    {
      "staffID": 17703,
      "userName": "AMSLOTSV"
    },
    {
      "staffID": 17708,
      "userName": "CRKOSTEC"
    },
    {
      "staffID": 17709,
      "userName": "MRAKER"
    },
    {
      "staffID": 17710,
      "userName": "NMARVIDS"
    },
    {
      "staffID": 17711,
      "userName": "CABECK"
    },
    {
      "staffID": 17712,
      "userName": "JRBERGGR"
    },
    {
      "staffID": 17714,
      "userName": "TDBOLLES"
    },
    {
      "staffID": 17715,
      "userName": "JHBURK"
    },
    {
      "staffID": 17716,
      "userName": "SRBURMEI"
    },
    {
      "staffID": 17718,
      "userName": "PRDARSOW"
    },
    {
      "staffID": 17719,
      "userName": "TADIEGNA"
    },
    {
      "staffID": 17720,
      "userName": "MADOLD"
    },
    {
      "staffID": 17721,
      "userName": "JMDUDGEO"
    },
    {
      "staffID": 17722,
      "userName": "JPDUSSL"
    },
    {
      "staffID": 17723,
      "userName": "RMELMER"
    },
    {
      "staffID": 17724,
      "userName": "BWERICKS"
    },
    {
      "staffID": 17725,
      "userName": "CJFAHEY"
    },
    {
      "staffID": 17726,
      "userName": "EAFITZLO"
    },
    {
      "staffID": 17727,
      "userName": "BRFROSLE"
    },
    {
      "staffID": 17728,
      "userName": "BLHATTON"
    },
    {
      "staffID": 17730,
      "userName": "KMHAUGER"
    },
    {
      "staffID": 17731,
      "userName": "WMHEATH"
    },
    {
      "staffID": 17733,
      "userName": "MJJAKACK"
    },
    {
      "staffID": 17734,
      "userName": "CDJOHNSO"
    },
    {
      "staffID": 17735,
      "userName": "MCLANMAN"
    },
    {
      "staffID": 17736,
      "userName": "WLLEHNER"
    },
    {
      "staffID": 17737,
      "userName": "PSLENZME"
    },
    {
      "staffID": 17738,
      "userName": "RPLORINS"
    },
    {
      "staffID": 17739,
      "userName": "CAMCCALL"
    },
    {
      "staffID": 17740,
      "userName": "STMERRIT"
    },
    {
      "staffID": 17742,
      "userName": "MBPETERS"
    },
    {
      "staffID": 17743,
      "userName": "JMPIPER"
    },
    {
      "staffID": 17744,
      "userName": "JPPSYCK"
    },
    {
      "staffID": 17745,
      "userName": "SBQUINN"
    },
    {
      "staffID": 17746,
      "userName": "RRAKOTZ"
    },
    {
      "staffID": 17747,
      "userName": "AMSCHELL"
    },
    {
      "staffID": 17748,
      "userName": "TDSHIPMA"
    },
    {
      "staffID": 17749,
      "userName": "TSINK"
    },
    {
      "staffID": 17750,
      "userName": "JXSMITH"
    },
    {
      "staffID": 17751,
      "userName": "RGSORENS"
    },
    {
      "staffID": 17752,
      "userName": "BNTORBOR"
    },
    {
      "staffID": 17753,
      "userName": "MRWAHL"
    },
    {
      "staffID": 17754,
      "userName": "TLWOLD"
    },
    {
      "staffID": 17756,
      "userName": "KRMENSCH"
    },
    {
      "staffID": 17758,
      "userName": "DBKOENIG"
    },
    {
      "staffID": 17759,
      "userName": "MWDAVIS"
    },
    {
      "staffID": 17760,
      "userName": "JMLEHMA"
    },
    {
      "staffID": 17762,
      "userName": "JWCOMMA"
    },
    {
      "staffID": 17763,
      "userName": "AMLAFORC"
    },
    {
      "staffID": 17764,
      "userName": "GUEST"
    },
    {
      "staffID": 17765,
      "userName": "TAMINSTE"
    },
    {
      "staffID": 17766,
      "userName": "ARUDE"
    },
    {
      "staffID": 17767,
      "userName": "CLLUNDY"
    },
    {
      "staffID": 17768,
      "userName": "DMDEROO"
    },
    {
      "staffID": 17771,
      "userName": "TAEIYNC"
    },
    {
      "staffID": 17773,
      "userName": "MJDEHN"
    },
    {
      "staffID": 17774,
      "userName": "JAVOERST"
    },
    {
      "staffID": 17775,
      "userName": "BDGIESE"
    },
    {
      "staffID": 17785,
      "userName": "SJJOHNSO"
    },
    {
      "staffID": 17792,
      "userName": "JJTSCHID"
    },
    {
      "staffID": 17794,
      "userName": "DTARCHA"
    },
    {
      "staffID": 17796,
      "userName": "LGJOHNSO"
    },
    {
      "staffID": 17797,
      "userName": "TLPULJU"
    },
    {
      "staffID": 17798,
      "userName": "MJGIESE"
    },
    {
      "staffID": 17799,
      "userName": "TAKVAM"
    },
    {
      "staffID": 17800,
      "userName": "JHRENCHI"
    },
    {
      "staffID": 17803,
      "userName": "BTCAHILL"
    },
    {
      "staffID": 17804,
      "userName": "JHPARKS"
    },
    {
      "staffID": 17805,
      "userName": "EADMYTRU"
    },
    {
      "staffID": 17807,
      "userName": "KMRUHLAN"
    },
    {
      "staffID": 17808,
      "userName": "CPPIERRO"
    },
    {
      "staffID": 17809,
      "userName": "KLSABO"
    },
    {
      "staffID": 17812,
      "userName": "BDMADSON"
    },
    {
      "staffID": 17813,
      "userName": "BLSAND"
    },
    {
      "staffID": 17814,
      "userName": "DVNELSON"
    },
    {
      "staffID": 17816,
      "userName": "KIRBYJ"
    },
    {
      "staffID": 17817,
      "userName": "SLSTRECK"
    },
    {
      "staffID": 17818,
      "userName": "TJBOWMAN"
    },
    {
      "staffID": 17819,
      "userName": "JRMCKAY"
    },
    {
      "staffID": 17820,
      "userName": "PJBONEST"
    },
    {
      "staffID": 17821,
      "userName": "DLIRISH"
    },
    {
      "staffID": 17822,
      "userName": "BLDUPAY"
    },
    {
      "staffID": 17823,
      "userName": "KAERICKS"
    },
    {
      "staffID": 17824,
      "userName": "TRFOSTER"
    },
    {
      "staffID": 17825,
      "userName": "LWMEVERD"
    },
    {
      "staffID": 17826,
      "userName": "NLJOHNSO"
    },
    {
      "staffID": 17834,
      "userName": "CEKOLLER"
    },
    {
      "staffID": 17835,
      "userName": "PMMCDO"
    },
    {
      "staffID": 17837,
      "userName": "JJBELTER"
    },
    {
      "staffID": 17839,
      "userName": "PCIJAIL"
    },
    {
      "staffID": 17840,
      "userName": "SDCLAI"
    },
    {
      "staffID": 17842,
      "userName": "BOARDING"
    },
    {
      "staffID": 17843,
      "userName": "BKTBOARD"
    },
    {
      "staffID": 17844,
      "userName": "BKKIRBY"
    },
    {
      "staffID": 17845,
      "userName": "BDFISCHER"
    },
    {
      "staffID": 17846,
      "userName": "BJFISCHER"
    },
    {
      "staffID": 17847,
      "userName": "BKMCDERMOTT"
    },
    {
      "staffID": 17848,
      "userName": "BRPIECEK"
    },
    {
      "staffID": 17849,
      "userName": "BMGUNDERMAN"
    },
    {
      "staffID": 17850,
      "userName": "BDKISSIAH"
    },
    {
      "staffID": 17851,
      "userName": "BPWARD"
    },
    {
      "staffID": 17852,
      "userName": "BDPALKKI"
    },
    {
      "staffID": 17853,
      "userName": "BRECKMAN"
    },
    {
      "staffID": 17854,
      "userName": "BHFREDLUND"
    },
    {
      "staffID": 17855,
      "userName": "BDEDWARDS"
    },
    {
      "staffID": 17856,
      "userName": "BCPLANT"
    },
    {
      "staffID": 17857,
      "userName": "BSHIRSCH"
    },
    {
      "staffID": 17858,
      "userName": "BMROFF"
    },
    {
      "staffID": 17859,
      "userName": "BGWHITE"
    },
    {
      "staffID": 17860,
      "userName": "SWARTOUTB"
    },
    {
      "staffID": 17861,
      "userName": "JRCOX"
    },
    {
      "staffID": 17862,
      "userName": "CCCZECH"
    },
    {
      "staffID": 17863,
      "userName": "RJHELD"
    },
    {
      "staffID": 17864,
      "userName": "KDHILL"
    },
    {
      "staffID": 17865,
      "userName": "BMATT"
    },
    {
      "staffID": 17866,
      "userName": "MKFOSTERR"
    },
    {
      "staffID": 17868,
      "userName": "JRMATHIS"
    },
    {
      "staffID": 17869,
      "userName": "BEWILLIA"
    },
    {
      "staffID": 17870,
      "userName": "RAJOHNSO"
    },
    {
      "staffID": 17871,
      "userName": "BJBACKOWSKI"
    },
    {
      "staffID": 17872,
      "userName": "RJCLARK"
    },
    {
      "staffID": 17873,
      "userName": "AQJACKSO"
    },
    {
      "staffID": 17874,
      "userName": "LMJOHNSO"
    },
    {
      "staffID": 17876,
      "userName": "MHANSON"
    },
    {
      "staffID": 17877,
      "userName": "MKFOSTER"
    },
    {
      "staffID": 17878,
      "userName": "JAMARTY"
    },
    {
      "staffID": 17880,
      "userName": "JEHOSK"
    },
    {
      "staffID": 17883,
      "userName": "KRSHERARD"
    },
    {
      "staffID": 17884,
      "userName": "TLALLEN"
    },
    {
      "staffID": 17885,
      "userName": "JMSCHLIE"
    },
    {
      "staffID": 17886,
      "userName": "JMSEWAR"
    },
    {
      "staffID": 17887,
      "userName": "KDEISENH"
    },
    {
      "staffID": 17888,
      "userName": "AGEEHAN"
    },
    {
      "staffID": 17889,
      "userName": "TREDMOND"
    },
    {
      "staffID": 17891,
      "userName": "RRODEGA"
    },
    {
      "staffID": 17892,
      "userName": "MSHABAZZ"
    },
    {
      "staffID": 17893,
      "userName": "CMCREGO"
    },
    {
      "staffID": 17894,
      "userName": "JSBOWER"
    },
    {
      "staffID": 17895,
      "userName": "ICHELGESON"
    },
    {
      "staffID": 17896,
      "userName": "BKTTEST"
    },
    {
      "staffID": 17897,
      "userName": "CMSOBCI"
    },
    {
      "staffID": 17898,
      "userName": "KGUSTAFSON"
    },
    {
      "staffID": 17899,
      "userName": "RRKNICK"
    },
    {
      "staffID": 17900,
      "userName": "RSCHAEFER"
    },
    {
      "staffID": 17901,
      "userName": "BNOWACK"
    },
    {
      "staffID": 17902,
      "userName": "BMILLER"
    },
    {
      "staffID": 17903,
      "userName": "SWARTOUTB-LIMIT"
    },
    {
      "staffID": 17904,
      "userName": "JPSTRIC"
    },
    {
      "staffID": 17905,
      "userName": "RRNINOW"
    },
    {
      "staffID": 17906,
      "userName": "KRHEMMER"
    },
    {
      "staffID": 17907,
      "userName": "JDPINOR"
    },
    {
      "staffID": 17908,
      "userName": "MARIONJAIL1"
    },
    {
      "staffID": 17910,
      "userName": "MARIONJAIL2"
    },
    {
      "staffID": 17911,
      "userName": "MARIONJAIL3"
    },
    {
      "staffID": 17912,
      "userName": "MARIONWORK1"
    },
    {
      "staffID": 17913,
      "userName": "MARIONWORK2"
    },
    {
      "staffID": 17914,
      "userName": "MARIONWORK3"
    },
    {
      "staffID": 17915,
      "userName": "MARIONSUPER1"
    },
    {
      "staffID": 17916,
      "userName": "MARIONSUPER2"
    },
    {
      "staffID": 17917,
      "userName": "MARIONSUPER3"
    },
    {
      "staffID": 17918,
      "userName": "PETERB"
    },
    {
      "staffID": 17920,
      "userName": "Elite"
    },
    {
      "staffID": 17924,
      "userName": "Elite1"
    },
    {
      "staffID": 17926,
      "userName": "Elite2"
    },
    {
      "staffID": 17928,
      "userName": "Eswaran"
    },
    {
      "staffID": 17929,
      "userName": "Balaji"
    }
  ]