import { createSlice } from "@reduxjs/toolkit";

const bookingSlice = createSlice({
  name: "bookings",
  initialState: {
    bookings: null,
    LastBookingsFromAPI: null,
    bookingsFieldsEnabled: false,
  },
  reducers: {
    setBookings: (state, action) => {
      state.bookings = { ...state?.bookings, ...action.payload };
    },
    setBookingsNull: (state) => {
      state.bookings = null;
    },
    setLastBookingsFromAPI: (state, action) => {
      state.LastBookingsFromAPI = action.payload;
    },
    setBookingsFieldsEnabled: (state, action) => {
      state.bookingsFieldsEnabled = action.payload;
    },
  },
});

export const {
  setBookings,
  setBookingsNull,
  setLastBookingsFromAPI,
  setBookingsFieldsEnabled,
} = bookingSlice.actions;
export default bookingSlice.reducer;
