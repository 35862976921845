import { ClientparamsProvider } from "./ClientparamsContext";
import DocumentControlContextProvider from "./DocumentControlContext";
import { FolderSearchProvider } from "./FolderSearchContext";
import { ReflistProvider } from "./RefListContext";
import { BookingQueueSearchContextProvider } from "./SearchContexts/BookingQueue";
import { BookingsProvider } from "./BookingsContext";

const GlobalContextProvider = ({ children }) => {
  return (
    <>
      <ClientparamsProvider>
        <ReflistProvider>
          <BookingQueueSearchContextProvider>
            <DocumentControlContextProvider>
              <FolderSearchProvider>
                <BookingsProvider>{children}</BookingsProvider>
              </FolderSearchProvider>
            </DocumentControlContextProvider>
          </BookingQueueSearchContextProvider>
        </ReflistProvider>
      </ClientparamsProvider>
    </>
  );
};

export default GlobalContextProvider;
