import { Spin } from "antd";
import { LoaderContainer } from "./styles";

const Loader = ({ themeMode = "dark", spinTextColor, spinColor }) => {
  return (
    <>
      <LoaderContainer spinTextColor={spinTextColor} spinColor={spinColor}>
        <Spin className={`${themeMode}-spin-loader`} />
          <span className="spin-loading-text">
            Loading
          </span>
      </LoaderContainer>
    </>
  );
};

export default Loader;
