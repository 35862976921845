import React from "react";
import dayjs from "dayjs";
import { Col } from "antd";
import { ErrorMessage } from "formik";
import styled from "styled-components";
import { INPUT_VARIANT } from "@/common/elements/InputElements/const";
import TextField from "@/common/elements/InputElements/TextField";
import FormItem from "@/common/elements/InputElements/FormItem.jsx/index.jsx";
import Select from "@/common/elements/InputElements/Select";
import CommonDatePicker from "@/common/elements/DatePicker";
import { StyledDate } from "@/components/InmateDetails/styles";
import {
  getSQLTypeToHTMLType,
  getOptionsFromRefList,
  findAgefromDOB,
  generateRules,
} from "@/util/common_function";
import { StyledFlex } from "../styles";
import Checkbox from "../elements/InputElements/CheckBox";

const StyledColumn = styled(Col)`
  .ant-col {
    min-height: auto !important;
  }
`;

const DynamicForm = ({ formAttributes, i, form, person }) => {
  const {
    label,
    columnName,
    tableName,
    type,
    refListName,
    isID,
    isStaff,
    disabled,
    longStringRequired,
  } = formAttributes;
  const htmlType = getSQLTypeToHTMLType(type);
  const paramContext = tableName + ":" + columnName;

  const isAgencyInmate = columnName == "agency" && person == "INMATE";

  return (
    <React.Fragment>
      {
        // checkField(paramContext) &&
        <StyledColumn key={i} span={24 / 4}>
          {(htmlType === "text" || htmlType === "number") && (
            <>
              <FormItem
                name={columnName}
                label={label}
                rules={generateRules(paramContext, label, !isAgencyInmate)}
              >
                <TextField
                  type={htmlType}
                  placeholder={label}
                  disabled={disabled}
                />
              </FormItem>
            </>
          )}
          {htmlType === "select" && (
            <>
              <FormItem
                name={columnName}
                label={label}
                rules={generateRules(paramContext, label, !isAgencyInmate)}
              >
                <Select
                  options={getOptionsFromRefList(
                    refListName || columnName,
                    isStaff ? true : false,
                    longStringRequired ? true : false,
                    isID ? true : false
                  )}
                  placeholder={label}
                  showSearch
                  variant="outlined"
                ></Select>
              </FormItem>
            </>
          )}
          {htmlType === "datetime" && (
            <FormItem
              name={columnName}
              label={label}
              rules={generateRules(paramContext, label, !isAgencyInmate)}
            >
              <CommonDatePicker
                placeholder="MM/DD/YYYY HH:mm"
                format="MM/DD/YYYY HH:mm"
                showTime={true}
                inputProps={{
                  maxLength: 16,
                }}
              />
            </FormItem>
          )}
          {htmlType === "date" && (
            <FormItem
              name={columnName}
              label={label}
              rules={generateRules(paramContext, label, !isAgencyInmate)}
            >
              <CommonDatePicker
                placeholder="MM/DD/YYYY"
                format="MM/DD/YYYY"
                showTime={false}
                disableFutureDates={columnName !== "dlExpiration"}
                inputProps={{
                  maxLength: 10,
                }}
              />
            </FormItem>
          )}
          {htmlType === "checkbox" && (
            <StyledFlex align="center" style={{ height: "100%" }}>
              <FormItem
                name={columnName}
                valuePropName="checked"
                label={label}
                layout="horizontal"
                className="form-item-checkbox-left"
                style={{ marginBottom: "0" }}
              >
                <Checkbox />
              </FormItem>
            </StyledFlex>
          )}
        </StyledColumn>
      }
    </React.Fragment>
  );
};

export default DynamicForm;
