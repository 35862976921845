export const SERVER_URL = "http://35.169.152.152";
// export const SERVER_URL = "https://demo.imatsbi2.com";

const ipv4Regex =
  /^(https?:\/\/)?((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

let origin = window.location.origin;

const isLocalHost = origin.includes("localhost");

let PORT = "";

if (ipv4Regex.test(origin)) {
  origin = origin;
  PORT = ":8080";
}

export const BASE_URL = `${origin + "" + PORT + "/api/"}`;
export const UPLOAD_URL = !isLocalHost
  ? `${origin}/api/`
  : `${SERVER_URL + ":8080"}/api/`;
export const PHOTOS_BASE_URL = !isLocalHost
  ? `${origin}/photos/`
  : `${SERVER_URL}/photos/`;
export const PHOTOS_BASE_URL_LOGO = !isLocalHost
  ? `${origin}/photos/logos/`
  : `${SERVER_URL}/photos/logos/`;

const API_ENDPOINTS = {
  before_login: {
    login: `login`,
    verify: `verify`,
  },
  folderSearch: `bookings/jackets/search`,
  bookingQueue: `bookingsQueue/search`,
  clientParams: `clientParams/all`,
  refList: `ref-list/getRefList`,
  bookings: `bookings/getBookings/id/9999`,
  bookingsCreate: `bookings/create`,
  countyLogo: `clientParams/Globals/ClientLogo`,
  bookingIntakeCreate: "bookings_intake/create",
  staffIdByUser: "get/staff/details/username/",
  imageUpload: "bookings/bio/bioPhotos/photos",
  documentUpload: "bookings/bio/documents",
  latestBooking: "get/latest/bookings/details",
  getBookingByID: "bookings/bookingNumber/id/",
};

export default API_ENDPOINTS;
