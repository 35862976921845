import { ConfigControlContext } from "@/contexts/ConfigControlContext";
import { useContext, useState } from "react";
import { ContentContainer, SidebarHeader } from "@/components/Sidebar/styles";
import { ControlBox,QuickInfoContainer,ConfigSidebarControlContainer} from "./styles";
import CascaderMenu from "@/common/elements/CascaderMenu";
import TextField from "@/common/elements/InputElements/TextField";
import Checkbox from "@/common/elements/InputElements/CheckBox";
import { Flex } from "@/common/styles";
import Button, { BUTTON_VARIANT } from "@/common/elements/Button";
import { multiLevelOptions } from "@/demo-data";
import FormItem from "@/common/elements/InputElements/FormItem.jsx";

const ConfigSidebarControl = (props) => {
  const [actions, setActions] = useContext(ConfigControlContext);
  const [quickInfoVisible,setQuickInfoVisible] = useState(true)
  
  const options = multiLevelOptions
 
  const handleQuickInfoVisible = () =>{
    setQuickInfoVisible(!quickInfoVisible)
  }
  const handleChange = (value) => {
    console.log('Selected value:', value);
  };

  return (
    <ConfigSidebarControlContainer>
         <QuickInfoContainer>
          <div className="header">
            <span className="left">component info</span>
            <span className="right" onClick={handleQuickInfoVisible}>
              {quickInfoVisible ? 'Hide':'Show'}
            </span>
          </div>
          { quickInfoVisible && (
            <>
            <h1>
             Medical Questionaire
            </h1>
            <div className="text">
              A brief component description goes here. It may contain inline <a>hyperlinks</a> to documentation, to other components, even open tables to the right.
            </div>
            </>
          )

          }
        </QuickInfoContainer>
        <ContentContainer className="container-card">
        <ControlBox>
          <SidebarHeader className="header">HOW DO I?</SidebarHeader>
          <CascaderMenu
            items={options}
            onSelect={handleChange}
            placeholder="Select an option"
            placement="bottomLeft"
          />
        </ControlBox>
        
        <ControlBox>

        <SidebarHeader className="header">Create New</SidebarHeader>
        <CascaderMenu
            items={options}
            onSelect={handleChange}
            placeholder="Select an option"
            togglerClass="highlight-green-btn"
            placement="bottomLeft"
          />
        
        </ControlBox>
        <ControlBox>

        <SidebarHeader className="header">search for something</SidebarHeader>
        <div style={{marginBottom:"15px"}}>
        <CascaderMenu
            items={options}
            onSelect={handleChange}
            placeholder="Select an option"
            togglerClass="highlight-yellow-btn"
            placement="bottomLeft"
            label="Category"
          />
          </div>
          <div style={{marginBottom:"15px"}}>
          <CascaderMenu
            items={options}
            onSelect={handleChange}
            placeholder="Select a location"
            togglerClass="highlight-yellow-btn"
            placement="bottomLeft"
            label="Category"
          />
          </div>
         
         <div style={{marginBottom:"15px"}}>
            <FormItem label="Forced" className="dark-form-item">
              <TextField variant="filled"/>
           </FormItem>
         </div>
         
         <Flex style={{marginBottom:"15px",justifyContent:"center"}}>
              <FormItem label="Limit To Previous" 
                  layout="horizontal" className="form-item-checkbox-left dark-form-item">
                  <Checkbox />
              </FormItem>
         </Flex>
         
         <Flex style={{marginBottom:"15px",justifyContent:"center"}}>
          <Button style={{marginRight:"10px"}}>Reset</Button>
          <Button>Search</Button>
         </Flex>

        </ControlBox>
        </ContentContainer>
    </ConfigSidebarControlContainer>
  );
};

export default ConfigSidebarControl;
