import AuthGuard from "@/common/AuthGuard";
import { lazy, Suspense } from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import App from "./App";
import Loader from "./common/elements/Loader";
import ConfigSidebarControl from "./components/ConfigFeatures/ConfigSidebarControl";
import ConfigControlProvider from "./contexts/ConfigControlContext";


//lazy loaded components
const DocumentControl = lazy(() => import("./components/DocumentControl"));
const SearchBookingQueue = lazy(() => import("./components/SearchBookingQueue"));
const BookingQueue = lazy(() => import("./pages/BookingQueue"));
const Charges = lazy(() => import("./pages/Charges"));
const CreateBooking = lazy(() => import("./pages/CreateBooking"));
const Login = lazy(() => import("./pages/Login"));
const InmateMovement = lazy(() => import("./pages/InmateMovement"));
const InmateCitation = lazy(() => import("./pages/InmateCitation"));
const FolderSearch = lazy(() => import("./pages/FolderSearch"));
const FolderSearchSidebar = lazy(() => import("./pages/FolderSearch/FolderSearchSidebar"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Detentions = lazy(() => import("./pages/Detentions"));
const JailIncidents = lazy(() => import("./pages/JailIncidents"));
const VisitorLog = lazy(() => import("./pages/VisitorLog"));
const SecurityMainPage = lazy(() => import("./pages/Security"));

// Config Routes
const ConfigPage = lazy(() => import("./pages/Config"));
const ConfigStage = lazy(() => import("./pages/Config/ConfigStage"));
const ConfigHome = lazy(() => import("./pages/Config/ConfigHome"));
const ConfigStageLevelOne = lazy(() => import("./pages/Config/ConfigStageLevelOne"));
const ConfigStageLevelTwo = lazy(() => import("./pages/Config/ConfigStageLevelTwo"));
const ConfigStageLevelThree = lazy(() => import("./pages/Config/ConfigStageLevelThree"));
const BookingsPage = lazy(() => import("./pages/Bookings"));

export const ROUTES_PATH = {
  BOOKING_QUEUE: "/booking-queue",
  LOGIN: "/login",
  ALL: "*",
  INDEX: "/",
  CREATE_BOOKING: "/create-booking",
  CHARGES: "/charges",
  INMATE_MOVEMENT: "/inmate-movement",
  DASHBOARD: "/dashboard",
  JACKET_SEARCH: "folder-search",
  CITATIONS: "/citations",
  DETENTIONS: "/detentions",
  INCIDENTS: "/jail-incidents",
  CONFIG: "/config",
  VISITOR_LOG: "/visitor-log",
  BOOKINGS: "/bookings",
  SECURITY: "/security",
};

const SuspenseWrapper = ({ children }) => <Suspense fallback={<Loader />}>{children}</Suspense>;

// This is Protected routes (After login path)
export const PrivateRoutes = [
  {
    path: ROUTES_PATH.INDEX,
    element: <Navigate to={ROUTES_PATH.CREATE_BOOKING} replace />,
  },
  {
    path: ROUTES_PATH.DASHBOARD,
    name: "Dashboard",
    element: (
      <SuspenseWrapper>
        <Dashboard />
      </SuspenseWrapper>
    ),
  },
  {
    path: ROUTES_PATH.CREATE_BOOKING,
    name: "Booking",
    element: (
      <SuspenseWrapper>
        <CreateBooking />
      </SuspenseWrapper>
    ),
    sidebarComponent: (
      <SuspenseWrapper>
        <DocumentControl />
      </SuspenseWrapper>
    ),
  },
  {
    path: ROUTES_PATH.BOOKING_QUEUE,
    name: "Booking Queue",
    element: (
      <SuspenseWrapper>
        <BookingQueue />
      </SuspenseWrapper>
    ),
    sidebarComponent: (
      <SuspenseWrapper>
        <SearchBookingQueue />
      </SuspenseWrapper>
    ),
  },
  {
    path: ROUTES_PATH.CHARGES,
    name: "Booking",
    element: (
      <SuspenseWrapper>
        <Charges />
      </SuspenseWrapper>
    ),
    sidebarComponent: (
      <SuspenseWrapper>
        <DocumentControl />
      </SuspenseWrapper>
    ),
  },
  {
    path: ROUTES_PATH.INMATE_MOVEMENT,
    name: "Inmate Movement",
    element: (
      <SuspenseWrapper>
        <InmateMovement />
      </SuspenseWrapper>
    ),
    sidebarComponent: (
      <SuspenseWrapper>
        <DocumentControl />
      </SuspenseWrapper>
    ),
  },
  {
    path: ROUTES_PATH.JACKET_SEARCH,
    name: "Folder Search",
    element: (
      <SuspenseWrapper>
        <FolderSearch />
      </SuspenseWrapper>
    ),
    sidebarComponent: (
      <SuspenseWrapper>
        <FolderSearchSidebar />
      </SuspenseWrapper>
    ),
  },
  {
    path: ROUTES_PATH.CITATIONS,
    name: "Citations",
    element: (
      <SuspenseWrapper>
        <InmateCitation />
      </SuspenseWrapper>
    ),
    sidebarComponent: (
      <SuspenseWrapper>
        <DocumentControl />
      </SuspenseWrapper>
    ),
  },
  {
    path: ROUTES_PATH.DETENTIONS,
    name: "Detentions",
    element: (
      <SuspenseWrapper>
        <Detentions />
      </SuspenseWrapper>
    ),
    sidebarComponent: (
      <SuspenseWrapper>
        <DocumentControl />
      </SuspenseWrapper>
    ),
  },
  {
    path: ROUTES_PATH.INCIDENTS,
    name: "Jail Incidents",
    element: (
      <SuspenseWrapper>
        <JailIncidents />
      </SuspenseWrapper>
    ),
    sidebarComponent: (
      <SuspenseWrapper>
        <DocumentControl />
      </SuspenseWrapper>
    ),
  },
  {
    path: ROUTES_PATH.SECURITY,
    name: "Security",
    element: (
      <SuspenseWrapper>
        <SecurityMainPage />
      </SuspenseWrapper>
    ),
    sidebarComponent: (
      <SuspenseWrapper>
        <DocumentControl />
      </SuspenseWrapper>
    ),
  },
  {
    path: ROUTES_PATH.CONFIG,
    name: "Config",
    element: (
      <SuspenseWrapper>
        <ConfigPage />
      </SuspenseWrapper>
    ),
    sidebarComponent: (
      <SuspenseWrapper>
        <ConfigControlProvider>
          <ConfigSidebarControl />
        </ConfigControlProvider>
      </SuspenseWrapper>
    ),
    children: [
      {
        path: "",
        name: "Config Home",
        element: (
          <ConfigHome />
        ),
      },
      {
        path: ":module",
        name: "Config Stage",
        element: (
          <ConfigStage />
        ),
      },
      {
        path: "stage-level-one",
        name: "Config Details",
        element: (
          <ConfigStageLevelOne />
        ),
      },
      {
        path: "stage-level-two",
        name: "Config Details",
        element: (
          <ConfigStageLevelTwo />
        ),
      },
      {
        path: "stage-level-three",
        name: "Config Details",
        element: (
          <ConfigStageLevelThree />
        ),
      }
    ]
  },
  {
    path: ROUTES_PATH.VISITOR_LOG,
    name: "Visitor Log",
    element: (
      <SuspenseWrapper>
        <VisitorLog />
      </SuspenseWrapper>
    ),
    sidebarComponent: (
      <SuspenseWrapper>
        <DocumentControl />
      </SuspenseWrapper>
    ),
  },
  {
    path: ROUTES_PATH.BOOKINGS,
    name: "Bookings",
    element: (
      <SuspenseWrapper>
        <BookingsPage />
      </SuspenseWrapper>
    ),
    sidebarComponent: (
      <SuspenseWrapper>
        <DocumentControl />
      </SuspenseWrapper>
    ),
  },
  //Add all the routes before this line.
  { path: ROUTES_PATH.ALL, element: <Navigate to={ROUTES_PATH.CREATE_BOOKING} replace /> },
];

const router = createBrowserRouter([
  {
    path: "login",
    element: (
      <SuspenseWrapper>
        <Login />
      </SuspenseWrapper>
    ),
  },
  {
    path: "/",
    element: (
      <AuthGuard>
        <App />
      </AuthGuard>
    ),
    children: PrivateRoutes,
  },
]);

export default router;
