import React, { createContext, useContext, useState } from "react";

const BookingsContext = createContext();

export const BookingsProvider = ({ children }) => {
  const [bookingsearch, setBookingSearch] = useState(null);

  return (
    <BookingsContext.Provider value={{ bookingsearch, setBookingSearch }}>
      {children}
    </BookingsContext.Provider>
  );
};

export const useBookingContext = () => useContext(BookingsContext);
