import { createSlice } from "@reduxjs/toolkit";

const refListSlice = createSlice({
  name: "refLists",
  initialState: {
    refLists: null,
    isLoading: true,
  },
  reducers: {
    setRefLists: (state, action) => {
      state.refLists = action.payload;
    },
    getRefLists: (state) => {
      return state.refLists;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setRefLists, getRefLists, setLoading } = refListSlice.actions;
export default refListSlice.reducer;
