import clientParams from "./common_variable";
// import { refLists } from "./ref_list_all";
import * as Yup from "yup";
import { staffList } from "./staff_list";
import { useSelector } from "react-redux";
import { useEffect } from "react";

var refLists = [];
const SetReflist = () => {
  const refList = useSelector((state) => state?.refListReducer?.refLists);
  useEffect(() => {
    refLists = refList;
  }, [refList]);
};

export default SetReflist;
/**
 * Checks if the object is empty.
 * @param {Object} obj - The object to check.
 * @returns {boolean} - Returns true if the object is empty, false otherwise.
 */
const isEmptyObject = (obj) => {
  if (obj === null || typeof obj === "undefined") return true;
  if (Object.keys(obj)?.length === 0) {
    return true;
  }
  return Object.keys(obj).every((o) => {
    !isNonEmptyVariable(obj[o]);
  });
};

/**
 * Converts an object to an array of key-value pairs with custom labels.
 * @param {Object} obj - The object to convert.
 * @param {String} keyLabel - The label for the key in the resulting objects.
 * @param {String} valueLabel - The label for the value in the resulting objects.
 * @returns {Array} - An array of objects with custom key-value labels.
 */
const objectToArray = (obj, keyLabel = "key", valueLabel = "value") => {
  if (Object.keys(obj).length === 0) {
    return [];
  }

  return Object.keys(obj)
    .filter((key) => obj[key] !== "") // Filter out keys with empty values
    .map((key) => ({
      [keyLabel]: key,
      [valueLabel]: obj[key],
    }));
};

/**
 * Converts an object to a query string, supporting nested objects.
 *
 * @param {Object} obj - The object to convert to query parameters.
 * @param {String} [prefix] - Optional prefix for nested keys.
 * @returns {String} - The resulting query string.
 */
const toQueryString = (obj, prefix) => {
  const queryString = Object.keys(obj).map((key) => {
    const value = obj[key];
    const prefixedKey = prefix ? `${prefix}[${key}]` : key;

    if (typeof value === "object" && value !== null) {
      return toQueryString(value, prefixedKey);
    } else {
      return `${encodeURIComponent(prefixedKey)}=${encodeURIComponent(value)}`;
    }
  });

  return queryString.join("&");
};

/**
 * Checks if a variable is non-empty.
 *
 * A variable is considered non-empty if it is not undefined, null, or an empty string.
 *
 * @param {*} value - The variable to check.
 * @returns {boolean} - Returns true if the variable is non-empty; otherwise, false.
 */
const isNonEmptyVariable = (value) => {
  return typeof value !== "undefined" && value !== null && value !== "";
};

/**
 * Creates a debounced version of a function that delays its execution
 * until after a specified wait time has passed since it was last called.
 *
 * @param {Function} func - The function to debounce.
 * @param {number} wait - The delay in milliseconds to wait before invoking the function.
 * @returns {Function} - A debounced function that delays calling `func` until after `wait` milliseconds.
 */
const debounce = (func, wait) => {
  let timeout;

  return function (...args) {
    const context = this;

    clearTimeout(timeout);

    timeout = setTimeout(() => {
      func.apply(context, args);
    }, wait);
  };
};

/**
 * Generates an array of numbers from 1 to the given number.
 *
 * @param {number|string} number - The number up to which the array should be generated.
 *                                 Can be a number or a string representation of a number.
 * @returns {number[]} - An array containing numbers from 1 to the given number.
 */
const generateArray = (number) => {
  // Ensure the input is a number by parsing it into an integer.
  number = parseInt(number);

  // Create an array of the specified length (number),
  // then use Array.from to fill it with numbers from 1 to `number`.
  return Array.from({ length: number }, (_, i) => i + 1);
};

/**
 * To diplay component if it has permission
 *
 * @param {string} paramContext - This arguments is used to check with condition if it is there or not
 * @returns {Boolean} - It will returns boolean value
 */
const checkField = (paramContext) => {
  // const { clientParam } = useClientContext();
  return clientParams?.find(
    (cp) => cp?.paramContext === paramContext && cp?.paramName === "Display"
  )?.paramValue;
};

/**
 * Generates a validation schema by using Yup validator
 *
 * @param {string} paramContext - This arguments is used to build validator
 * @returns {Object} - An object will return with entire schema that contains all matched conditions
 */
const checkFieldValidation = (
  paramContext,
  paramType = "text",
  label = "",
  clientList = null
) => {
  return new Promise((resolve, reject) => {
    let schema = Yup;

    switch (paramType) {
      case "text":
        schema = schema.string();
        break;
      case "number":
        schema = schema.number();
        break;
      default:
        schema = schema.string();
        break;
    }

    const clientParamValidation = Object.values(clientList)
      .map((item) => item.paramContext)
      .filter(
        (context) => context.toLowerCase() === paramContext?.toLowerCase()
      );

    // const clientParamValidation = clientList?.filter(
    //     (cp) => cp?.paramContext?.toLowerCase() === paramContext?.toLowerCase()
    // );

    if (!clientParamValidation || clientParamValidation.length === 0) {
      // If no validations are found, resolve schema as optional
      return resolve(schema.optional());
    }

    let isValid = true;
    clientList?.forEach((cpv, index) => {
      if (cpv?.paramName?.toLowerCase() === "required" && cpv?.paramValue) {
        schema = schema.required(`${label || "This field"} is required`); // Add required validation
        isValid = false;
      }

      if (cpv?.paramName?.toLowerCase() === "regularexpression") {
        if (cpv?.paramValue?.includes("||")) {
          const [pattern, message] = cpv?.paramValue?.split("||") || ["", ""];
          schema = schema.matches(
            new RegExp(pattern),
            message || "Invalid format"
          ); // Add regex validation
          isValid = false;
        }
      }

      if (index === clientList?.length - 1) {
        if (isValid) {
          schema = schema.optional();
        }

        resolve(schema);
      }
    });
  });
};

/**
 * Retrieves a list of options from the reference list based on the provided key.
 * - Includes a default "Select" option at the beginning of the list.
 * - Sorts the retrieved items in descending order by `Sequence_Number`.
 * - Handles cases where the reference list or its items are undefined gracefully.
 *
 * @param {string} key - The name of the reference list to search for.
 * @returns {Array<Object>} - An array of objects representing the options,
 *                            with a default "Select" option included at the start.
 *
 */
const getOptionsFromRefList = (
  key,
  isStaff = false,
  longStringRequired = false,
  isID = false
) => {
  if (isStaff) {
    return staffList?.map((sl) => {
      return {
        value: sl?.staffID,
        label: sl?.userName,
      };
    });
  }

  const refList = refLists?.find(
    (rl) => rl?.refListName?.toLowerCase() === key?.toLowerCase()
  );

  // Extract and sort items, adding the default item at the start
  let sortedItems = refList?.refListItems?.filter((rli) => rli?.enabled) || [];

  // ?.sort((a, b) => b?.sequenceNumber - a?.sequenceNumber) || [];
  return sortedItems?.map((aa) => {
    return {
      value: isID ? aa?.refListItemGlobalId : aa?.defaultString,
      enabled: aa?.enabled,
      label: longStringRequired ? aa?.longString : aa?.defaultString,
    };
  });
};

/**
 * Maps SQL data types to their corresponding HTML input types for form rendering.
 *
 * - Supports a variety of SQL data types, including character, numeric, date/time, and boolean types.
 * - Decides the most appropriate HTML input type based on SQL type characteristics.
 * - Provides a fallback to "text" for unsupported or unknown SQL types.
 *
 * @param {string} sqlType - The SQL data type to be mapped (e.g., "varchar(255)", "int", "datetime").
 * @returns {string} - The corresponding HTML input type (e.g., "text", "number", "date", "textarea").
 *
 * Example:
 * Input: sqlType = "varchar(255)"
 * Output: "text"
 *
 * Input: sqlType = "datetime"
 * Output: "datetime-local"
 *
 * Notes:
 * - Character types ("char", "varchar") are mapped to "text" or "textarea" based on their size.
 * - Integer and floating-point types are mapped to "number".
 * - Boolean types ("bit") are mapped to "select" (can be customized for "checkbox").
 * - Date/time types are mapped to respective HTML5 input types ("date", "datetime-local").
 */
const getSQLTypeToHTMLType = (sqlType) => {
  let type = ""; // Default to empty

  // Handle character types
  if (sqlType?.toLowerCase()?.includes("char")) {
    // Extract size within parentheses, e.g., varchar(255)
    const sizeMatch = sqlType?.match(/\((\d+)\)/); // Regular expression to extract size
    const size = sizeMatch ? parseInt(sizeMatch[1]) : null;

    // Decide between text or textarea
    type = size && size > 255 ? "textarea" : "text";
  }
  // Handle integer types
  else if (sqlType?.toLowerCase()?.includes("int")) {
    type = "number";
  }
  // Handle boolean types
  else if (sqlType?.toLowerCase()?.includes("bit")) {
    type = "select"; // Changed to checkbox for boolean values
  }
  // Handle datetime types
  else if (sqlType?.toLowerCase()?.includes("datetime")) {
    type = "datetime"; // HTML5 datetime input
  }
  // Handle datetime types
  else if (sqlType?.toLowerCase()?.includes("date")) {
    type = "date"; // HTML5 datetime input
  }
  // Handle date types
  else if (sqlType?.toLowerCase()?.includes("date")) {
    type = "date";
  }

  // Handle floating point or decimal numbers
  else if (
    sqlType?.toLowerCase()?.includes("float") ||
    sqlType?.toLowerCase()?.includes("decimal")
  ) {
    type = "number"; // Number type can handle decimals
  }
  // Handle large text fields
  else if (sqlType?.toLowerCase()?.includes("text")) {
    type = "textarea";
  }

  // Handle large text fields
  else if (sqlType?.toLowerCase()?.includes("checkbox")) {
    type = "checkbox";
  }

  // Fallback to text if no match
  else {
    type = "text";
  }

  return type;
};

/**
 * Handles setting the "touched" state for all fields in a form.
 * - If there are validation errors, all fields with errors are marked as touched.
 * - Otherwise, all fields in the form are marked as touched.
 *
 * @param {Object} ref - A reference to the form object, typically passed from a form library like Formik.
 *
 * Example Usage:
 * handleFormForSetTouched(formRef);
 */
const handleFormForSetTouched = (ref) => {
  if (!ref || !ref.current) return; // Ensure the ref object is valid.

  const { errors, values, setTouched } = ref.current;

  // Determine which fields to mark as touched
  const fieldsToMark = !isEmptyObject(errors) ? errors : values;

  // Set all relevant fields as touched (true by default, can be customized)
  setTouched(
    Object.keys(fieldsToMark).reduce((acc, key) => {
      acc[key] = true; // Mark the field as touched (or false if needed)
      return acc;
    }, {})
  );
};

/**
 * Converts an array of fields into an object by dynamically validating each field.
 *
 * - Uses `checkFieldValidation` to validate each field asynchronously.
 * - Aggregates the results into a single object where keys are column names and values are the validation rules or configurations.
 * - Handles errors gracefully using `Promise.allSettled`.
 *
 * @param {Array} fieldArray - An array of field objects, each containing `columnName`, `tableName`, `type`, and `label`.
 * @returns {Promise<Object>} - A Promise that resolves to an object with column names as keys and validation results as values.
 *
 * Example Input:
 * [
 *   { columnName: "username", tableName: "users", type: "string", label: "Username" },
 *   { columnName: "email", tableName: "users", type: "email", label: "Email" }
 * ]
 *
 * Example Output:
 * {
 *   username: "validation rule for username",
 *   email: "validation rule for email"
 * }
 */
// const arrayToObject = async (fieldArray, clientValues) => {

//     try {
//         // Validate each field asynchronously and collect results
//         Promise.allSettled(
//             fieldArray?.map(async (field) => {
//                 const validationRule = await checkFieldValidation(
//                     `${field?.tableName?.toLowerCase()}:${field?.columnName?.toLowerCase()}`,
//                     field?.type?.toLowerCase(),
//                     field?.label,
//                     clientValues
//                 );
//                 return { [field?.columnName]: validationRule };
//             })
//         )?.then((da) => {
//         }).catch((e) => {
//         });

//         // Aggregate results into a single object
//         // const resultObject = validationResults?.reduce((acc, result) => {
//         //     if (result.status === "fulfilled" && result.value) {

//         //         return { ...acc, ...result.value };
//         //     }

//         //     // Optional: Handle rejected promises
//         //     return acc;
//         // }, {});

//         // return resultObject;
//     } catch (error) {
//         throw new Error(`Error processing field validations: ${error.message}`);

//     }
// };

const arrayToObject = async (fieldArray, clientValues) => {
  try {
    // Validate each field asynchronously and collect results
    const validationResults = await Promise.allSettled(
      fieldArray?.map(async (field) => {
        const validationRule = await checkFieldValidation(
          `${field?.tableName?.toLowerCase()}:${field?.columnName?.toLowerCase()}`,
          field?.type?.toLowerCase(),
          field?.label,
          clientValues
        );
        return { [field?.columnName]: validationRule };
      })
    );

    // Aggregate results into a single object
    const resultObject = validationResults.reduce((acc, result) => {
      if (result.status === "fulfilled") {
        // Merge the fulfilled result into the accumulated object
        return { ...acc, ...result.value };
      }

      // Optional: Log or handle rejected promises
      console.error(`Validation failed for a field: ${result.reason}`);
      return acc;
    }, {});

    return resultObject; // Return the aggregated result object
  } catch (error) {
    console.error(`Error processing field validations: ${error.message}`);
    throw new Error(error.message);
  }
};

/**
 * To diplay component if it has permission
 *
 * @param {string} paramContext - This arguments is used to check with condition if it is there or not
 * @returns {Boolean} - It will returns boolean value
 */
const checkRequiredField = (paramContext) => {
  // const { clientParam } = useClientContext();
  const result = clientParams?.find(
    (cp) =>
      cp?.paramContext?.toLowerCase() === paramContext.toLowerCase() &&
      cp?.paramName.toLowerCase() === "required"
  )?.paramValue;

  return result;
};

/**
 * Calculates the age based on the given date of birth.
 *
 * @param {string|Date} dob - The date of birth. It can be a string or a Date object.
 * @returns {number} The calculated age.
 */
const findAgefromDOB = (dob) => {
  const dobDate = new Date(dob);
  const today = new Date();

  let age = today.getFullYear() - dobDate.getFullYear();
  const monthDiff = today.getMonth() - dobDate.getMonth();
  const dayDiff = today.getDate() - dobDate.getDate();

  // Adjust age if the birthday has not occurred yet this year
  if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
    age--;
  }

  return age;
};

/**
 * Generates validation rules for a form field based on the provided parameter context.
 *
 * - Checks the `clientParams` array for matching parameter contexts.
 * - Adds validation rules for required fields and regular expressions.
 * - Supports custom error messages for both required and regex validations.
 *
 * @param {string} paramContext - The context parameter to check for validation rules.
 * @param {string} label - The label of the field, used in validation messages.
 * @returns {Array<Object>} - An array of validation rule objects.
 *
 * Example:
 * Input: paramContext = "username", label = "Username"
 * Output: [
 *   { required: true, message: "Username is required" },
 *   { pattern: /^regex$/, message: "Invalid format" }
 * ]
 */
const generateRules = (paramContext, label, setRequired = true) => {
  const rules = [];
  clientParams?.forEach((cp) => {
    if (cp?.paramContext?.toLowerCase() === paramContext.toLowerCase()) {
      if (cp?.paramName == "required" && cp?.paramValue && setRequired) {
        rules.push({
          required: true,
          message: `${label} is required`,
        });
      } else if (cp?.paramName?.toLowerCase() === "regularexpression") {
        const [pattern, message] = cp?.paramValue?.split("||") || ["", ""];
        rules.push({
          pattern: new RegExp(`^${pattern}$`),
          message: message || "Invalid format",
        });
      }
    }
  });

  return rules;
};

const filteredUndefinedData = (data) => {
  const filteredData = Object.fromEntries(
    Object.entries(data).filter(([_, value]) => value !== undefined)
  );

  return filteredData;
};

const getRefListItemsbyName = (key) => {
  const refList = refLists?.find(
    (rl) => rl?.refListName?.toLowerCase() === key?.toLowerCase()
  );

  // Extract and sort items, adding the default item at the start
  return refList?.refListItems?.filter((rli) => rli?.enabled) || [];
};

export {
  isEmptyObject,
  objectToArray,
  toQueryString,
  isNonEmptyVariable,
  debounce,
  generateArray,
  checkField,
  checkFieldValidation,
  getSQLTypeToHTMLType,
  getOptionsFromRefList,
  handleFormForSetTouched,
  arrayToObject,
  checkRequiredField,
  findAgefromDOB,
  generateRules,
  filteredUndefinedData,
  getRefListItemsbyName,
};
