import { createSlice } from "@reduxjs/toolkit";

const bookingPhotoSlice = createSlice({
    name: "bookingPhoto",
    initialState: {
        img: null
    },

    reducers: {
        setBookingPhoto: (state, action) => {
            state.img = action.payload;
        },
        getBookingPhoto: (state) => {
            return state.img;
        }
    }
});

export const { setBookingPhoto, getBookingPhoto } =
    bookingPhotoSlice.actions;
export default bookingPhotoSlice.reducer;