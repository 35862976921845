import { createSlice } from "@reduxjs/toolkit";

const docsSlice = createSlice({
  name: "docs",
  initialState: {
    docs: [],
  },
  reducers: {
    setDocs: (state, action) => {
      state.docs = action.payload;
    },
    getDocs: (state) => {
      return state.docs;
    },
  },
});

export const { setDocs, getDocs } = docsSlice.actions;
export default docsSlice.reducer;
