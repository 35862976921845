import { ant, CONSTANT } from "@/util/ant_var";
import { Checkbox, Form, Input, Select, Switch, theme, Upload } from "antd";
import styled, { css } from "styled-components";
const { useToken } = theme;
// const { token } = useToken();

const labelStyle = css`
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  display: block;
`;

const antLabelStyle = css`
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
`;
export const selectWithBG = css`
  background-color: #07678c;
  border: 4px solid #0c79a4;
  color: white;
  &:hover {
    background-color: #0b3d5b;
    border: 4px solid var(--background-color-secondary);
  }
  &:disabled {
    background-color: #07678c;
    border-color: #0c79a4;
  }
`;

export const FormGroup = styled(Form.Item)`
  margin-bottom: ${({ margin = "default" }) =>
    margin === "none" ? 0 : "15px"};
  width: ${({ fullWidth = true }) => (fullWidth ? "100%" : "fit-content")};
  min-width: ${({ minWidth }) => minWidth && minWidth};
  label {
    ${labelStyle};
  }
`;

export const ANT_FormItem = styled(Form.Item).withConfig({
  shouldForwardProp: (prop) => prop !== "paddingTop",
})`
  padding-top: ${({ paddingTop }) => paddingTop && paddingTop};

  margin-bottom: ${({ margin = "default" }) =>
    margin === "none" ? 0 : "15px"};
  width: ${({ fullWidth = true }) => (fullWidth ? "100%" : "fit-content")};
  min-width: ${({ minWidth }) => minWidth && minWidth};
  .ant-row.ant-form-item-row .ant-col {
    min-height: auto;
  }
  label {
    ${antLabelStyle};
  }
  .ant-form-item-label {
    label {
      color: var(--label-color-primary);
    }
  }

  &.dark-form-item {
    .ant-form-item-label {
      label {
        color: var(--text-color-blue);
      }
    }
  }
  &.text-center {
    label {
      ${antLabelStyle};
      text-align: center;
    }
  }
  &.ant-form-item-horizontal.form-item-checkbox-left {
    .ant-form-item-label {
      order: 2;
      flex-grow: 1;
      text-align: left;
    }
    .ant-form-item-control {
      min-width: 28px;
      max-width: 28px;
    }
  }
  &.ant-form-item-horizontal.form-item-label-right {
    margin-bottom: 0;
    .ant-col.ant-form-item-control {
      order: 1;
      flex-grow: initial;
      min-width: auto;
    }
    .ant-col.ant-form-item-label {
      order: 2;
      padding-left: 14px;
    }
  }
  .ant-input-filled {
    background-color: ${CONSTANT.WHITE};
  }
  .ant-input-filled.ant-input-disabled,
  .ant-form-item-control-input-content textarea.ant-input-disabled,
  .ant-input-filled.ant-input-affix-wrapper > input.ant-input {
    color: var(--input-text-disabled);
  }
`;

export const FormLabel = styled.div``;

export const FormControl = styled(Input)`
  // padding: 5px 10px;
  // width: 100%;

  // &.intake-form,
  // textarea.intake-form {
  //   color: rgba(2, 87, 147, 0.7);
  //   width: 100%;
  //   background-color: #ffffff !important;
  //   border: 2px solid rgba(82, 140, 181, 0.27);

  //   transition: all 0.3s ease-in-out;
  //   color: black !important;

  //   &:hover {
  //     border-color: rgba(82, 140, 181, 0.72);
  //   }

  //   &:focus-visible {
  //     border-color: #2aa4e3;
  //   }

  //   &.is-invalid {
  //     border-color: var(--bs-form-invalid-border-color);
  //   }
  // }
  &.ant-input-outlined[disabled] {
    color: var(--input-text-disabled);
  }
`;

export const ANT_FormSelect = styled(Select)`
  width: 100%;
  /* text-transform: uppercase; */
  .ant-select-selector {
    box-shadow: none !important;
  }
  .ant-select-selection-wrap .ant-select-selection-item {
    color: ${CONSTANT.BLACK} !important;
  }
  .ant-select-selection-search .ant-select-selection-search-input {
    // This is the placeholder for select
    color: var(--grey-color-light);
  }

  &.ant-select-filled .ant-select-selector {
    border-color: ${CONSTANT.PRIMARY_500};
    .ant-select-selection-item {
      color: ${CONSTANT.BLUE} !important;
    }
  }
  &.ant-select-filled.ant-select-open {
    .ant-select-selector {
      background: ${CONSTANT.PRIMARY_500} !important;
    }
  }
  &.ant-select-filled.ant-select-focused {
    .ant-select-selector {
      background: ${CONSTANT.PRIMARY_500} !important;
    }
  }
  &.ant-select-outlined .ant-select-selector {
    border-width: 1px !important;
  }
  &.filled-select .ant-select-selector {
    background-color: ${ant.token.colorPrimary};
    color: rgba(255, 255, 255, 0.88) !important;
    border-color: rgb(12, 121, 164) !important;
  }

  &.ant-select-filled .ant-select-selection-placeholder {
    color: var(--text-color-blue) !important;
  }
`;

export const FormCheck = styled(Checkbox)`
  color: ${CONSTANT.SECONDARY};
  font-weight: 500;

  &.ant-text-dark {
    color: ${CONSTANT.BLACK} !important;
  }
`;

export const StyledSwitch = styled(Switch)``;

export const ANT_Upload = styled(Upload)`
  display: block;
`;
