import dayjs from "dayjs";
import { useMemo } from "react";
import { StyledDatePicker, StyledRangePicker } from "./styles";
import { rest } from "lodash";

const CommonDatePicker = ({
  value,
  onChange,
  placeholder = "Select date",
  format = "YYYY-MM-DD",
  disabled = false,
  allowClear = true,
  disableFutureDates = false,
  range = false,
  showTime = true,
  ...rest
}) => {
  const disabledDate = (current) => {
    if (!disableFutureDates) return false;
    return current && current > dayjs().endOf("day");
  };

  const PickerComponent = useMemo(
    () => (range ? StyledRangePicker : StyledDatePicker),
    [range]
  );

  return (
    <PickerComponent
      width="100%"
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      format={format}
      allowClear={allowClear}
      disabledDate={disabledDate}
      showTime={showTime}
      {...rest}
    />
  );
};

export default CommonDatePicker;
