import { createSlice } from "@reduxjs/toolkit";

const bookingIntakeSlice = createSlice({
  name: "bookingIntake",
  initialState: {
    bookingIntake: null,
  },
  reducers: {
    setBookingIntake: (state, action) => {
      state.bookingIntake = action.payload;
    },
    getBookingIntake: (state) => {
      return state.bookingIntake;
    },
  },
});

export const { setBookingIntake, getBookingIntake } =
  bookingIntakeSlice.actions;
export default bookingIntakeSlice.reducer;
