import styled from "styled-components";

export const LoaderContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== 'spinColor' && prop !== 'spinTextColor',
})`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding: 5px;
  margin: 5px 0;

  .dark-spin-loader{
      .ant-spin-dot-item{
        background: ${({ spinColor }) => spinColor || "#5b86ff"};
      }
  }
  
  .dark-spin-loader + span.spin-loading-text,
  .dark-spin-loader ~ span.spin-loading-text{
     color: ${({ spinTextColor }) => spinTextColor || '#FFFFFF'} !important;
   }
  

   
  .light-spin-loader{
      .ant-spin-dot-item{
        background: ${({ spinColor }) => spinColor || '#0B3D5B'} !important;
      }
  }
  
  .light-spin-loader + span.spin-loading-text,
  .light-spin-loader ~ span.spin-loading-text{
     color: ${({ spinTextColor }) => spinTextColor || "#041c2d"} !important;
   }

`;
