import { StyledBgDarkBlueMixin } from "@/common/styles";
import styled from "styled-components";


export const QuickInfoContainer = styled.div`
position:relative;
border-radius: 6px;
box-shadow: 0px 1px 1px rgba(17, 115, 153, 0.5) inset,
 0px -1px 0px rgba(5, 92, 126, 0.49) inset, 2px 2px 7px rgba(0, 0, 0, 0.2);
background-color: rgba(4, 42, 67, 1);
padding: 8px 12px;
margin-bottom: 15px;

.header {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  .left {
    color: rgba(50, 178, 230, 1);
  }
  .right {
    color: var(--text-blue);
    cursor: pointer;
  }
}

h1 {
  color: #fff396;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 15px;
}
.text {
  color: var(--text-blue);
  font-weight: 400;
  a {
    color: #fff396;
  }
}
`
export const ControlBox = styled.div`
padding: 15px 8px 20px 8px;
border-radius: 8px;
box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25) inset,
  0.5px 0.5px 1px rgba(0, 0, 0, 0.41) inset;
background-color: rgba(4, 28, 45, 0.56);
margin-top: 5px;
margin-bottom: 10px;

.header {
  margin-bottom: 15px;
}

`
export const ConfigSidebarControlContainer = styled.div`
  display:flex;
  flex-direction:column;
  overflow-y:auto;
  height: 100%;
  .container-card {
  position:relative;
  }
`

export const StyledConfigReportsBar = styled.div`
${StyledBgDarkBlueMixin}
padding:5px 10px;
margin:15px 0;
label{
font-size:12px !important;
}
`

export const StyledConfigStagebar = styled.div`
  display:flex;
  align-items: stretch;
  width:100%;
  min-height:32px;
  background:var(--bg-light-container);
  border-radius:var(--border-radius-sm);
  &.stage-selected{
    
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    &::before{
    content: "";
    position:absolute;
    top:0;
    left: -8.5px;
    width: 0; 
    height: 0; 
    border-top:16px solid transparent;
    border-bottom: 16px solid transparent;
    border-right: 16px solid  var(--bg-light-container);
    z-index: -1;
   }

  }
`
export const StyledConfigStagebarContainer = styled.div`
  position: relative;

  .deploy-col{
  .deploy-icon{
      display: inline-flex;
    align-items: center;
  height:32px;
  text-transform:uppercase;
  padding:0 10px;
  font-size: 14px;
    font-weight: 600;
    color: var(--text-color-white);
  img{margin-right:10px;
      position: relative;
    top: 2px;
  }
  }
 .deploy-commit{
 span{margin:0 9px;}
 }
}

`
export const StyledConfigStagebarCol = styled.div`
  position:relative;
  display: inline-flex;
  align-items: center;
  line-height:32px;
  .config-icon{
  position: relative;
  display: inline-flex;
  img{
   position:absolute;
   top:-28px;
   width:50px;
  }
   padding-right:55px;
   
  }
  .initial-text{
  padding-left:5px;
  font-size:16px;
  font-weight:500;
  text-transform:uppercase;
  color:var(--text-blue);
  
  .green{
  color:var(--text-color-green-light)
  }
 
  .yellow{
  color:var(--text-color-yellow)
  }
  
  .white{
  color:var(--text-color-white)
  }

  }
`
export const StyledConfigSelected = styled.div`
 display:flex;
 .white-text{
  padding-left:10px;
  font-size:16px;
  font-weight:500;
  text-transform:uppercase;
  color:var(--text-color-white);
 }
 button{
 margin-left:10px;
 font-size:14px;
 font-weight:600;
 padding-left:8px;
 padding-right:8px;
 img{margin-right:4px;}
 &:last-childe{margin-left:0;}
 }
`


export const StyledLogHeader = styled.div`
display:flex;
justify-content:space-between;
align-items: center;
min-height: 32px;
padding:5px 10px;

button{
font-size:12px;
font-weight:700;
}

.left{
display:inline-flex;
font-size: 12px;
font-weight: 700;
color: var(--text-color-blue-2);
text-transform: uppercase;
text-shadow: 0px 1px 3px rgba(6, 33, 59, 1);
.text-white{
font-weight: 600;
color: var(--text-color-white);
}
}

.right{
display:inline-flex;
line-height: normal;
}

img{position: relative;}
.state-down{
    img{transform: rotate(180deg);top:-1.5px;}
}
button{
padding:0;
height:auto;
}
`

export const StyledLogContent = styled.div`
background:var(--bg-deepest-dark);
border-bottom-right-radius: 6px;
border-bottom-left-radius: 6px;
.time{
width:80px;
max-width:90px;
}
.state-icon{
    width: 35px;
        max-width: 35px;
  img{max-width:24px;margin-right:10px;}
}   
`

export const StyledLogTable = styled.table`
  margin:0;
  padding:0;
  font-size:12px;
  font-weight:500;
  width:100%;
  .unsaved{
  color: var(--text-color-white);
  }
  .saved{
  color: var(--text-color-blue);
  }
  
  td {
       padding: 8px 10px;
       position:relative;
  }
  .td-action{ display:none;}
  tr:hover,.row-aciotn-touched{
  background-color:rgba(var(--core-highlight-color-rgba),37%);
  .td-action{
  display:block;
  > * {
  position:absolute;
  right:10px;
  top:0;
  }
  button{
  background: transparent;
  border: none;
  padding-left:0;
  }

  }
  }
`
export const StyledInnerHeader = styled.div`
    --primary-blue-800: #0B3D5B;
    padding:6px 10px;
    background: var(--primary-blue-800);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 1px 0px rgba(50, 178, 230, 0.4) inset;
    .add-button{
    width: 32px;
    height: 32px;
    border-radius: 100%;
    box-shadow: 0px -1px 0px rgba(30, 150, 197, 0.36) inset, 
    0px 1px 0px rgba(33, 143, 185, 1) inset, 
    2px 2px 7px rgba(10, 36, 65, 0.4);
    padding: 0;
    display: inline-flex;
    align-items: center;
    img{
    width: 20px;
    }
   }
    .inner-title{
   font-size: 1.25rem;
   text-transform: capitalize;
   font-weight: 400;
   margin:0;
   padding-left:9px;
   }
`
export const StyledPanelHeader = styled.div`
border-top-left-radius: 6px;
border-top-right-radius: 6px;
box-shadow: 0px 1px 0px rgba(50, 178, 230, 0.4) inset;
background-color: var(--bg-light-container);
padding:6px 10px;
 .panel-title{
   font-size: 1.25rem;
   text-transform: uppercase;
   font-weight: 400;
   margin:0;
 }
.add-button{
    width: 32px;
    height: 32px;
    border-radius: 100%;
    box-shadow: 0px -1px 0px rgba(30, 150, 197, 0.36) inset, 
    0px 1px 0px rgba(33, 143, 185, 1) inset, 
    2px 2px 7px rgba(10, 36, 65, 0.4);
    padding: 0;
    display: inline-flex;
    align-items: center;
    img{
    width: 20px;
    }
}
.workzone-title{
color:var(--text-color-white);
font-size:16px;
font-weight:500;
text-transform:uppercase;
padding-left:9px;
}
.link-group{
color:var(--text-color-blue);
text-decoration:none;
  &.active{
  color:var(--text-color-white);
  }
}

.search-result{
color:var(--text-color-white);
font-size:16px;
font-weight:500;
}
.filter-col{
> * {margin-bottom:0}
button{
font-size:12px;
font-weight:700;
padding: 0px;
&.btn-filter-toggle{
min-width:90px;
&:hover{color:var(--text-color-white);}
}
}
.input-group{
 > *{margin:0;padding-left:10px;}
 input{max-height:32px;}
}
}
button.collapsed{
img{margin-top: -12px;}
}
.btn-back{
position: relative;
font-weight:700;
margin-right:15px;
margin-left:15px;
}
.close-panel{
  font-weight:500;
  text-decoration:none;
  color:var(--text-blue);
  font-size:20px;
  cursor:pointer;
  span{
   margin-left:10px;
  }
   &:hover{
   color:var(--text-color-white)
   }
}
.collapse-expand-col{
  button{
    position: relative;
    font-size: 1.673rem;
    display: inline-flex;
    padding: 0;
   
    .icon{
    transform: rotate(90deg);
    margin-left:9px;
    }
    &:hover{
       color:var(--text-color-white) !important
    }
    
    &.expanded{
      .icon{
      transform: rotate(-90deg);
      }
    }
  }
}
`
export const StyledConfigWorkzoneContainer = styled.div`
  //  display: flex;
  // flex-direction: column;
  // height: 100%; 
  margin-bottom:15px;
  &:last-child{
  margin-bottom:0px;
  }
  .custom-panel-header{
   border-radius:6px;
  }
  &.panel-collapsed{
  
  }
  &.panel-expanded{
   .custom-panel-header{
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
   }
  }
`
export const StyledWorkzoneTableContainer = styled.div`
> * {
height: 100%;
overflow: visible;
overflow-x: visible;
}
> *, table{
border-bottom-left-radius: 6px;
border-bottom-right-radius: 6px;
}
&.table-hidden {
  display: none;
}

&.table-visible {
  display: block;
}
`
export const StyledFlashAlert = styled.div`
position: relative;
top: -15.5px;
.arrow-top{
content: "";
position: relative;
left: 15px;
top: 0;
width: 0;
height: 0;
border-bottom: 16px solid var(--bg-light-container);
border-left: 0px solid transparent;
border-right: 16px solid transparent;
}
`
export const StyledFlashAlertConent = styled.div`
display:flex;
align-items:center;
justify-content:space-between;
background:var(--bg-light-container) !important;
border-radius: 6px;
box-shadow: 0px 1px 0px rgba(47, 136, 175, 0.88) inset, 
0px -1px 0px rgba(27, 108, 147, 0.5) inset;
color:var(--text-color-white) !important;
button{
color:var(--text-color-blue) !important;
}
text-transform: uppercase;
font-weight:500 !important;
padding:8px 15px;
`