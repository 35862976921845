/* eslint-disable react/prop-types */
import { INPUT_VARIANT } from "../const";
import { ANT_FormItem } from "../styles";

const FormItem = ({
    id,
    label,
    className = "", // use dark-form-item for dark bg
    minWidth,
    margin = "default",
    children,
    layout = "vertical", // horizontal, vertical, inline
    colon=false,
    wrapperCol,
    labelPostion, // only have label-right
    rootClassName,
    ...rest
}) => {
    return (
        <ANT_FormItem
            layout={layout}
            // controlId={id}
            className={className}
            minWidth={minWidth}
            margin={margin}
            label={label}
            colon={colon}
            rootClassName={labelPostion == "label-right" ? "form-item-label-right":""}
            {...rest}
        >
            {children}
        </ANT_FormItem>
    );
};
export default FormItem;

