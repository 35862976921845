/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";

import crossBlue from "@/assets/cross-blue.svg";
import deptBadge from "@/assets/Dept_Badge.png";
import go from "@/assets/go.svg";
import kababMenuSm from "@/assets/kabab-menu-sm.svg";
import leftNavExtit from "@/assets/leftNavExtit.svg";
import map from "@/assets/map.svg";
import notificationBell from "@/assets/notification_bell.svg";
import search from "@/assets/search.svg";
import tasks from "@/assets/tasks.svg";

import Dropdown, { DROPDOWN_VARIANT } from "@/common/elements/Dropdown";
import {
  GoButton,
  HeaderContainer,
  MainHeader,
  NotificationButton,
  ReleaseQueueButton,
  SearchButton,
  SherifLogo,
  TaskButton,
  TasksNumber,
} from "./styles";
import { useNavigate, useLocation } from "react-router-dom";
import { ROUTES_PATH } from "@/routes";
import Button, { BUTTON_VARIANT } from "@/common/elements/Button";
import CascaderMenu from "@/common/elements/CascaderMenu";
import { configMenuItems } from "@/demo-data";
import { useDispatch, useSelector } from "react-redux";
import {
  setBookings,
  setBookingsFieldsEnabled,
  setBookingsNull,
} from "@/slices/bookingsSlice";

const ActionButton = ({ as: Component, ...props }) => (
  <Component {...props} className="action-btn" />
);

// eslint-disable-next-line react/display-name
const Header = React.forwardRef(({ routes }, ref) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { LastBookingsFromAPI } = useSelector((state) => state?.bookings);

  const logoutSession = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("userDetails");
    window.location.reload();
  };

  const location = useLocation();
  const [configPageSetting, setConfigPageSetting] = useState(false);

  useEffect(() => {
    const pathSegments = location.pathname.split("/").filter(Boolean);

    if (pathSegments[0] === "config") {
      setConfigPageSetting(true);
    } else if (pathSegments[0] === "create-booking") {
      dispatch(setBookingsFieldsEnabled(true));
    } else {
      setConfigPageSetting(false);
    }
  }, [location.pathname]);

  const loginUserName = JSON.parse(
    localStorage.getItem("userDetails")
  )?.username;

  const goOptions = [
    {
      key: "config",
      label: "Config",
      // children: [
      //   { key: 'config', label: 'Config Home' },
      //   { key: 'config/stage-level-one', label: 'Config Level One' },
      //   { key: 'config/stage-level-two', label: 'Config Level Two' },
      //   { key: 'config/stage-level-three', label: 'Config Level Three' },
      // ],
    },
    { key: "dashboard", label: "Dashboard" },
    // { key: "folder-search", label: "Folder Search" },
    {
      key: "bookings",
      label: "Bookings",
      children: [
        {
          key: "bookings_arrest-report",
          label: "Arrest Report",
          module: "arrest-report",
        },
        {
          key: "bookings_inmate-medical",
          label: "Inmate Medical",
          module: "inmate-medical",
        },
        {
          key: "bookings_inmate-property",
          label: "Inmate Property",
          module: "inmate-property",
        },
        {
          key: "bookings_inmate-officer-reports",
          label: "Inmate Officer Reports",
          module: "inmate-officer-reports",
        },
      ],
    },
    { key: "booking-queue", label: "Booking Queue" },
    { key: "create-booking", label: "Create Booking" },
    { key: "inmate-movement", label: "Inmate Movement" },
    { key: "charges", label: "Charges" },
    { key: "citations", label: "Citations" },
    { key: "detentions", label: "Detentions" },
    { key: "jail-incidents", label: "Jail Incidents" },
    { key: "visitor-log", label: "Visitor Log" },
    { key: "security", label: "Security" },
  ];
  const configMenuOptions = configMenuItems;

  const handleGoSelect = (selected) => {
    if (window.location.pathname?.includes(selected?.key?.split("_")[0])) {
      return;
    }

    dispatch(setBookings(LastBookingsFromAPI));
    dispatch(setBookingsFieldsEnabled(false));
    if (selected?.key === "config") {
      setConfigPageSetting(true);
      navigate(`/${selected?.key}`);
    } else if (selected?.key?.split("_")[0] == "bookings") {
      navigate("/bookings", {
        state: { module: selected?.key?.split("_")[1] },
      });
    } else if (selected?.key === "create-booking") {
      dispatch(setBookingsNull());
      dispatch(setBookingsFieldsEnabled(true));
      navigate("/folder-search");
    } else {
      navigate(`/${selected?.key}`);
    }
  };

  const handleConfigSelect = (selected) => {};

  return (
    <MainHeader ref={ref}>
      <HeaderContainer>
        {/* <ActionButton as={SearchButton} variant="dark" onClick={() => navigate(ROUTES_PATH.JACKET_SEARCH)}>
        <img alt="Search Icon" src={search} />
      </ActionButton> */}
        {configPageSetting ? (
          <>
            <ActionButton
              as={SearchButton}
              onClick={() => {
                dispatch(setBookingsNull());
                dispatch(setBookingsFieldsEnabled(true));
                navigate(ROUTES_PATH.CREATE_BOOKING);
              }}
            >
              <img src={leftNavExtit} width="50" />
            </ActionButton>
            <ActionButton as={SearchButton}>
              <img src={map} width="35" />
            </ActionButton>
          </>
        ) : (
          <ActionButton
            as={SearchButton}
            onClick={() => {
              dispatch(setBookingsNull());
              dispatch(setBookingsFieldsEnabled(true));
              navigate(ROUTES_PATH.JACKET_SEARCH);
            }}
          >
            <img alt="Search Icon" src={search} />
          </ActionButton>
        )}
        <div className="center">
          <div className="main">
            <>
              <CascaderMenu
                placeholder="GO"
                style={{ height: "100%" }}
                items={goOptions}
                showSelectedLabel={false}
                onSelect={handleGoSelect}
              />

              {configPageSetting && (
                <div style={{ marginLeft: "15px" }}>
                  <CascaderMenu
                    placeholder="CONFIG"
                    placement="topLeft"
                    items={configMenuOptions}
                    onSelect={handleConfigSelect}
                    showSelectedLabel={false}
                    togglerIcon={
                      <img alt="CONFIG Icon" src={kababMenuSm} width="100%" />
                    }
                  />
                </div>
              )}
              {/* <ActionButton as={GoButton} variant="dark" style={{minWidth:"110px",fontSize:"20px"}}>
            CONFIG&nbsp;
            <img alt="CONFIG Icon" src={kababMenuSm} width="24" />
          </ActionButton> */}
            </>

            {/* <Breadcrumb routes={routes} /> */}
            {/* <div className="breadcrumb-container">
            <ol className="breadcrumb">
              <li className="breadcrumb-item prev">Booking</li>
              <li className="breadcrumb-item active">Booking Queue</li>
            </ol>
          </div> */}
            {/* <div className="form-check form-switch">
            <input className="form-check-input" id="flexSwitchCheckDefault" role="switch" type="checkbox" />
          </div> */}
            {/* <button className="release-queue-btn no-btn-btn">Release Queue</button> */}
            <ReleaseQueueButton
              color="primary"
              type="link"
              variant="link"
            ></ReleaseQueueButton>
          </div>
          {/* <div className="tasks-right">
            <Dropdown
              label={'J. Patterson 5127'}
              variant={DROPDOWN_VARIANT.TEXT_BLUE}
            >
              <Dropdown.Item>Action</Dropdown.Item>
              <Dropdown.Item>Another action</Dropdown.Item>
              <Dropdown.Item>Something Else</Dropdown.Item>
            </Dropdown>
            <TasksNumber>
              <span className="green">27</span>
              <span className="red">32</span>
            </TasksNumber>
            <ActionButton as={TaskButton} color="primary" type="primary" variant="solid">
              <img alt="Tasks Icon" src={tasks} />
            </ActionButton>
          </div> */}
        </div>
        <div className="right">
          <ActionButton
            as={NotificationButton}
            color="primary"
            type="primary"
            variant="solid"
          >
            <img alt="Notification Bell" src={notificationBell} />
            {/* <span className='notification-count'>9+</span> */}
          </ActionButton>
          <Dropdown
            label={
              <SherifLogo
                alt="Sheriff Logo"
                className="sheriff-logo"
                src={deptBadge}
              />
            }
            variant={DROPDOWN_VARIANT.TEXT_BLUE}
          >
            <Dropdown.Item onClick={() => logoutSession()}>
              Logout {loginUserName}
            </Dropdown.Item>
          </Dropdown>
        </div>
      </HeaderContainer>
    </MainHeader>
  );
});

export default Header;
