import { CONSTANT } from "@/util/ant_var";
import { Collapse } from "antd";
import styled from "styled-components";

export const StyledAntCollapse = styled(Collapse)`
 margin-bottom:10px;

 &.ant-collapse:not(.ant-collapse-ghost){
 .ant-collapse-item {
    background: ${(props) => props.contentBg || 'inherit'};
    border-bottom:1px solid transparent;
    box-shadow: 0px 1px 1px rgba(17, 115, 153, 0.5) inset, 
                 0px -1px 0px rgba(5, 92, 126, 0.49) inset, 
                 2px 2px 7px rgba(0, 0, 0, 0.2);
    .ant-collapse-expand-icon{
    height:auto;
    transition: "transform 0.3s"
    .ant-collapse-arrow{
       color: ${(props) => props.headerColor || CONSTANT.BLUE};
     }
    }
    .ant-collapse-header {
      position-relative;
      width: 100%;
      align-items: stretch;
      background: ${(props) => props.headerBg || CONSTANT.PRIMARY};
      padding:0;
      &:hover{
      background:var(--bg-light-container2);
      }
      .ant-collapse-header-text{
      display:flex;
      padding: ${(props) => props.headerPadding || '8px'};
      color: ${(props) => props.headerColor || CONSTANT.BLUE};
      }
    }
    
    &.ant-collapse-item-active {
    
       .ant-collapse-header {
         background:var(--bg-light-container2);
        }
        .stacked-icon{
        background:var(--bg-light-container);
        }
    }

    .ant-collapse-content {
      background: ${(props) => props.contentBg || CONSTANT.PRIMARY};
      padding: ${(props) => props.contentPadding || 'inherit'};
      color: ${(props) => props.contentColor || 'var(--text-color-blue)'}
    }
    }
  }

  &.no-expand-icon{
    .ant-collapse-expand-icon{
    display:none !important;
    }
  }

  .ant-collapse-header-text{
   display:flex;
    .middle{
     display: inline-flex;
     align-items: center;
     padding-left:8px;
     flex:1;
     }
     .start{
     display: inline-flex;
     align-items: center
     }
     .end{display: inline-flex;}
    .header-title{
    flex:1;
    text-transform:uppercase;
    font-weight:500;
    order: 1;
    }
   }

   .ant-collapse-extra{
     display:inline-flex;
     order: 13;
     padding-left:7px;
    .nav-anchor{
      height:100%;
     }
   }
   // header-stacked

  .header-stacked-start,
  .header-stacked-end{
   .stacked-icon{
    background-color:var(--bg-light-container);
    padding:10px;
    margin-right:7px;
    width:50px;
    height:50px;
      img{
      width:100%;
      }
   }
  
  }

  .header-stacked-end{
  .stacked-icon{
   margin-right:0px;
   margin-left:0px;
   }

  }

`