import { useEffect, useRef, useState } from "react";
import { ANT_FormSelect } from "../styles";
import { useSelector } from "react-redux";
import { Spin } from "antd";
import { DownOutlined, LoadingOutlined } from "@ant-design/icons";

const Select = ({
  options = [],
  variant = "outlined",
  placeholder = "Select an option",
  loading = true,
  ...rest
}) => {
  const selectRef = useRef();
  const isRefLiistDataLoading = useSelector(
    (state) => state?.refListReducer?.isLoading
  );
  useEffect(() => {}, [isRefLiistDataLoading]);

  return (
    <>
      <ANT_FormSelect
        ref={selectRef}
        suffixIcon={
          loading && isRefLiistDataLoading ? (
            <Spin indicator={<LoadingOutlined spin />} />
          ) : (
            <DownOutlined />
          )
        }
        variant={variant}
        options={options}
        className={`${rest?.className}`}
        filterOption={(input, option) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
        {...rest}
        placeholder={placeholder}
        notFoundContent={
          isRefLiistDataLoading ? (
            <span style={{ color: "White" }}>Loading...</span>
          ) : null
        }
      ></ANT_FormSelect>
    </>
  );
};
export default Select;
