import { Container } from "react-bootstrap";
import styled from "styled-components";

export const MainContainer = styled(Container)`
  position:relative;
  min-height: 100dvh;
  @media screen and (max-width: 1400px) {
    max-width: unset;
  }

  @media screen and (min-width: 1400px) {
    max-width: 1520px;
  }
`;

export const ContentContainer = styled.div`
  flex: 1;
  min-width: 0; 
  /* margin-bottom: 10px; */
`;
